export function ApiGetNextOrActiveConference(body, callback) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body
    };

    fetch(process.env.REACT_APP_CMS_URL + '/api/getNextOrActiveConference', requestOptions)
        .then(async response => {
            const respones = await response.json();
            if (response.status == 200) {
                callback?.(respones);
            } else {
                console.log(respones);
            }
        });
}



export function ApiLoginUser(body, callback) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body
    };

    fetch(process.env.REACT_APP_CMS_URL + '/api/login', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}

export function ApiGetRegisterStatus(body, callback) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body
    };

    fetch(process.env.REACT_APP_CMS_URL + '/api/getRegisterStatus', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}

export function ApiRegisterDevice(body, callback) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body
    };

    fetch(process.env.REACT_APP_CMS_URL + '/api/registerDevice', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}

export function ApiLoginConfigUser(body, callback) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body
    };

    fetch(process.env.REACT_APP_CMS_URL + '/api/loginConfig', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}

export function ApiLogoutUser(token, callback) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': token },
        // body: body
    };


    fetch(process.env.REACT_APP_CMS_URL + '/api/logout', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}




