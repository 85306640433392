

import { useContext, useEffect, useRef } from 'react';
import { GlobalConfigContext } from "../contexts/globalConfigContext"
import io from "socket.io-client"


const SocketManagerConfig = () => {
    const { configSocketRef, currentUser } = useContext(GlobalConfigContext);

    useEffect(() => {
        configSocketRef.current = io.connect(process.env.REACT_APP_CMS_URL, {
            secure: true,
            reconnect: true,
            rejectUnauthorized: true,
            query: { token: currentUser.token }
        })

        configSocketRef.current.on('connect', function () {

            configSocketRef.current.emit("CONFIG_ADD_CLIENT_TO_SOCKETS");

        });

        return () => {
            console.log("inchis");
            configSocketRef.current.disconnect();
        }
        
    }, [])

    return (
        <> </>
    );
}

export default SocketManagerConfig;