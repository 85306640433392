import { useState, useRef, createContext } from "react"

export const GlobalConfigContext = createContext([{}, () => {}])

export const GlobalConfigProvider = ( props ) => {

    const configSocketRef = useRef();
   
    const [currentUser, setCurrentUser] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [currentError, setCurrentError] = useState(null);
    const [currentTextFilter, setCurrentTextFilter] = useState(null);

    return (
        <GlobalConfigContext.Provider value={{
            configSocketRef,            
            currentUser, setCurrentUser,
            currentPage, setCurrentPage,
            currentError, setCurrentError,
            currentTextFilter, setCurrentTextFilter         
        }}>
            {props.children}
        </GlobalConfigContext.Provider>
    )
}