import { useContext, useEffect } from 'react'
import styles from './PD_BaseListComponent.module.css'
import { GlobalContext } from '../../contexts/globalContext'
import { ProjectDetailsContext } from '../../contexts/projectDetailsContext'



import titleSvg from '../../graphics/linie titlu.svg'

import wave1 from '../../graphics/waves-01.svg'
import wave2 from '../../graphics/waves-02.svg'
import wave3 from '../../graphics/waves-03.svg'
import wave4 from '../../graphics/waves-04.svg'

import conf from '../../graphics/Popups/IconConf.png'
import confAdd from '../../graphics/Popups/IconConfAdd.png'
import topics from '../../graphics/Popups/IconTopics.png'
import topicsAdd from '../../graphics/Popups/IconTopicsAdd.png'
import filesAdd from '../../graphics/Popups/IconFileAdd.png'
import participantsAdd from '../../graphics/Popups/IconMembersAdd.png'
import participants from '../../graphics/Popups/IconMembers.png'
import meeting from '../../graphics/Popups/img-meeting.png'
import confSmall from '../../graphics/Popups/img-conf.png'




const PD_BaseListComponent = ({ title, list, button, toggleButton }) => {

    const { currentUser, selectedProject } = useContext(GlobalContext);
    const { setAddFiles, setAddParticipants, setAddGuest, setAddTopic, setCreateConference, setCreateMeeting } = useContext(ProjectDetailsContext);


    function openPopup() {
        switch (title) {
            case 'Conferences':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    setCreateConference(true);
                break;
            case 'Folders':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    setAddTopic(true);
                break;
            case 'Participants':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    setAddParticipants(true)
                break;
            default:
                if (selectedProject.topics?.length > 0)
                    setAddFiles(true)
                break;
        }
    };

    // const image = (() => {
    //     switch (title) {
    //         case 'Conferences':
    //             return wave1;
    //         case 'Folders':
    //             return wave2;
    //         case 'Directories':
    //             return wave2;
    //         case 'Participants':
    //             return wave3;
    //         default:
    //             return wave4;
    //     }
    // })();



    const noListImage = (() => {
        switch (title) {
            case 'Conferences':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return confAdd;
                return conf;
            case 'Folders':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return topicsAdd;
                return topics;
            case 'Participants':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return participantsAdd;
                return participants;
            default:
                return filesAdd;
        }
    })();

    const imgTitle = (() => {
        switch (title) {
            case 'Conferences':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return "Create New Conference";
                return "No Conference Created";
            case 'Folders':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return "Add Folder";
                return "No Folders & Subfolders";
            case 'Participants':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return "Add Participants";
                return "No Participants added";
            default:
                return "Add Files to Folders";
        }
    })();

    const imgDescription = (() => {
        switch (title) {
            case 'Conferences':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return "It looks like you don’t have any conferences. Open the main menu below to create your first conference and start organising your events.";
                return "It looks like you don’t have any conferences. Please check back later";
            case 'Folders':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return "Tap “New Topic” button from the menu below to create topics and structure your discussions.";
                return "Looks like the discussion hasn't started yet!";
            case 'Participants':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return "Select members to add to the project. They will gain access to resources and receive updates.";
                return "It looks like you don’t have any participants. Please check back later";
            default:
                return "Select a folder or subfolder to add files for sharing with other project members. You can upload files from your local device or search and select files from Cloud DOCS.";
        }
    })();

    useEffect(() => {
        // console.log(list)
    }, [])


    return (
        <div className={styles.component}>
            {/* <img src={image} className={styles.backgroundImage} alt={"fundal"} /> */}
            <div className={styles.title}>
                <img className={styles.titleGraphic} alt="icon" src={titleSvg} />
                {title.toUpperCase()} ({list.length > 0 ? list.length : 0})
            </div>
            
            {toggleButton && <div className={styles.toggleButton}>{toggleButton}</div>}
            {button && <div className={styles.button}>{button}</div>}
            {list.length > 0 ?
                <div className={styles.list} style={{ marginTop: toggleButton ? -10 : "" }}>{list} </div>
                :
                (title !== "Conferences" ? <div className={styles.borderImg} style={{ marginTop: toggleButton ? 440 : (button ? 395 : "") }}>
                    <div className={styles.noListImageBackrgound} onClick={openPopup}>
                        <img src={noListImage} className={styles.noListImage} alt={"noListImage"} />
                        <div className={styles.imgTitle}>{imgTitle}</div>
                        <div className={styles.imgDescription}>{imgDescription}</div>
                    </div>
                </div> 
                :
                <div className={styles.borderImg} style={{ marginTop: toggleButton ? 440 : (button ? 395 : "") }}>
                    <div className={styles.noListImageBackrgound} onClick={() => {
                        if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                            setCreateMeeting(true);
                    }}>
                        <img src={meeting} className={styles.noListImageSmall} alt={"noListImageSmall"} />
                        <div className={styles.imgTitle}>Add Meeting</div>
                        <div className={styles.imgDescription} style={{ marginTop: 10 }}>Schedule a collaborative meeting to discuss and develop projects with team members.</div>
                        <div className={styles.halfBackground} onClick={(e) => {
                            e.stopPropagation()
                            if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                                setCreateConference(true);
                        }}>
                            <img src={confSmall} className={styles.noListImageSmall} alt={"noListImageSmall"} />
                            <div className={styles.imgTitle}>Add Conference</div>
                            <div className={styles.imgDescription} style={{ marginTop: 10 }}>Organize a conference where moderators guide discussions on specific topics.</div>
                        </div>
                    </div>
                </div>)
            }
            <div className={styles.shadowUp}/>
            <div className={styles.shadowDown}/>
            
        </div>


    );

}

export default PD_BaseListComponent;