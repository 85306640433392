import { useContext, useEffect, useState } from 'react';
import { GlobalConfigContext } from "../contexts/globalConfigContext";


const CFG_ASideMenuComponent = () => {

    const { currentPage, setCurrentPage } = useContext(GlobalConfigContext);

    const goToPage = (newPage) => {
        setCurrentPage(newPage);
    }

    return (
        <aside id="sidebar" className="sidebar">
            <ul className="sidebar-nav" id="sidebar-nav">
                <li className="nav-heading">USERS MANAGEMENT</li>
                <li className="nav-item">
                    <a className={(currentPage == "Departments") ? "nav-link" : "nav-link collapsed"} href="#" onClick={() => goToPage("Departments")}>
                        <i className="bi bi-archive"></i>
                        <span>Departments</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className={(currentPage == "Functions") ? "nav-link" : "nav-link collapsed"} href="#" onClick={() => goToPage("Functions")}>
                        <i className="bi bi-asterisk"></i>
                        <span>Functions</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className={(currentPage == "Admins") ? "nav-link" : "nav-link collapsed"} href="#" onClick={() => goToPage("Admins")}>
                        <i className="bi bi-journals"></i>
                        <span>Admins</span>
                    </a>
                </li>

                <li className="nav-heading">ROOMS MANAGEMENT</li>
                <li className="nav-item">
                    <a className={(currentPage == "Locations") ? "nav-link" : "nav-link collapsed"} href="#" onClick={() => goToPage("Locations")}>
                        <i className="bi bi-pin-angle"></i>
                        <span>Locations</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className={(currentPage == "Rooms") ? "nav-link" : "nav-link collapsed"} href="#" onClick={() => goToPage("Rooms")}>
                        <i className="bi bi-ui-radios-grid"></i>
                        <span>Rooms</span>
                    </a>
                </li>
            </ul>
        </aside>
    );
}

export default CFG_ASideMenuComponent;