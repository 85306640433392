import {  useEffect, useRef } from 'react';

const AudioStreamComponent = (props) => {

    const audioRef = useRef(null);

    useEffect(() => {
        audioRef.current.srcObject = props.audioStream.stream;       
    }, [])

    return (
        <audio ref={audioRef} autoPlay></audio>

    );
}

export default AudioStreamComponent;