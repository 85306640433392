import { useRef, useContext, useState, useEffect, createRef } from 'react';

import { GlobalContext } from "../../contexts/globalContext";
import { ProjectDetailsContext } from '../../contexts/projectDetailsContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import styles from './AddFilesComponent.module.css'
import InputTextComponent from '../keyboard/InputTextComponent';
import KeyboardComponent from '../keyboard/KeyboardComponent';
import PopupBodyComponent from './common/PopupBodyComponent';
import PopupBaseComponent from './common/PopupBaseComponent';
import ClosePageButtonComponent from '../commons/ClosePageButtonComponent'
import CheckboxComponent from './common/CheckboxComponent';
import CustomDropdownComponent from '../commons/CustomDropdownComponent'
import LeftBodyComponent from './common/LeftBodyComponent';
import RightBodyComponent from './common/RightBodyComponent';

import filesAdd from '../../graphics/Popups/IconFileAdd.png'
import DarkButtonComponent from '../commons/DarkButtonComponent';
import LightButtonComponent from '../commons/LightButtonComponent';
import ElementTableComponent from './common/ElementTableComponent';

const AddFilesComponent = () => {


    const inputRef = useRef()
    const keyboardRef = useRef()
    const inputFileRef = useRef()
    const docsRef = useRef()
    const initialRender = useRef(true)
    const currentChoiceRef = useRef("all")

    const [docs, setDocs] = useState()
    const [allDocs, setAllDocs] = useState()

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);

    const [filesToBeUploaded, setFilesToBeUploaded] = useState([]);
    const [isOnFocus, setIsOnFocus] = useState(false)


    const [currentChoice, setCurrentChoice] = useState({
        value: "all",
        text: "All"
    })

    

    const { language, currentUser, selectedProject, socketRef, selectedParentForFiles, selectedRefTypeForFiles, setSelectedRefTypeForFiles } = useContext(GlobalContext);
    const { setAddFiles } = useContext(ProjectDetailsContext);

    const fileRefs = useRef(filesToBeUploaded.map(() => createRef()));


    const optionsTypes = [
        {
            value: "all",
            text: "All"
        },
        {
            value: "image",
            text: "Image"
        },
        {
            value: "pdf",
            text: "PDF"
        },
        {
            value: "word",
            text: "Word"
        },
        {
            value: "ms-excel",
            text: "Excel"
        },
        {
            value: "video",
            text: "Video"
        }
    ]


    useEffect(() => {
        socketRef.current.emit("PROJECT_GET_UNATTACHED_DOCUMENTS", { projectId: selectedProject._id, userId: currentUser._id }, (DOCS) => {
            setAllDocs(DOCS)
            setDocs(DOCS)
            docsRef.current = DOCS
        })

    }, [])

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            return;
        }
        handleSelectType(currentChoice.value)
        currentChoiceRef.current = currentChoice.value
    }, [currentChoice])

    const handleBrosweButtonClick = () => {
        inputFileRef.current.click();
    };

    const handleClickSelect = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(docs.map(doc => doc._id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    function handleFilesAdd() {
        let fileList = []
        for (let i = 0; i < docs.length; i++) {
            if (isCheck.includes(docs[i]._id)) {
                fileList.push(docs[i]._id)
            }
        }

        if (selectedRefTypeForFiles === undefined) {
            setSelectedRefTypeForFiles("Topic")
        }
        socketRef.current.emit("PROJECT_ADD_DOCUMENTS_TO_FOLDER", {
            docIds: fileList, userId: currentUser._id,
            refType: selectedRefTypeForFiles === undefined ? "Topic" : selectedRefTypeForFiles, parentId: selectedParentForFiles._id, projectId: selectedProject._id
        },() => { 
            setAddFiles(false) 
        })
        
    }

    const handleSelectType = (value) => {
        let result = []
        if (value !== "all") {
            result = allDocs.filter(doc => doc.type.includes(value));
        } else {
            result = allDocs
        }
        if (inputRef.current.value) {
            result = result.filter(doc => doc.name.toLowerCase().includes(inputRef.current.value.toLowerCase()));
        } 
        setDocs(result)
    };

    const onFileChange = event => {

        if (event.target.files.length > 0) {
            
            let filesArray = []
            for(let i = 0; i < event.target.files.length; i++){
                let file = event.target.files[i]

                file.newName = file.name
                file.previewURL = URL.createObjectURL(file)

                filesArray.push(file)
                fileRefs.current = [...fileRefs.current, createRef()]
            }
            setFilesToBeUploaded(filesArray)

            
        }

    };

    function addDocument() {

        let filesInfo = []
        for(let i = 0; i < filesToBeUploaded.length; i++){
            filesInfo.push({name: fileRefs.current[i].current.value ? fileRefs.current[i].current.value :  filesToBeUploaded[i].newName, type: filesToBeUploaded[i].type})
        }

        var data = { 
            ownerId: currentUser._id, 
            uploadFiles: filesToBeUploaded,
            filesInfo
        };

        if (selectedRefTypeForFiles === undefined) {
            setSelectedRefTypeForFiles("Topic")
        }

        socketRef.current.emit("PROJECT_ADD_NEW_LOCAL_FILES_TO_FOLDER", {
            data, parentId: selectedParentForFiles._id, refType: selectedRefTypeForFiles === undefined ? "Topic" : selectedRefTypeForFiles,
            projectId: selectedProject._id
        }, () => {
            setAddFiles(false)
        });
    }

    function onTextChanged() {
        let result = []
        if (inputRef.current.value) {
            result = docsRef.current.filter(doc => doc.name.toLowerCase().includes(inputRef.current.value.toLowerCase()));
        } else {
            result = docsRef.current
        }
        if (currentChoiceRef.current !== "all") {
            result = result.filter(doc => doc.type.includes(currentChoiceRef.current));
        }
        setDocs(result)
    }

    function handleClickAdd() {
        if (isCheck.length > 0) {
            handleFilesAdd();
        }
        if (filesToBeUploaded) {
            addDocument();
        }

    }

    function handleClose() {
        setAddFiles(false)
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files.length > 0) {
            let filesArray = []
            for(let i = 0; i < e.dataTransfer.files.length; i++){
                let file = e.dataTransfer.files[i]

                file.newName = file.name
                file.previewURL = URL.createObjectURL(file)

                filesArray.push(file)
                fileRefs.current = [...fileRefs.current, createRef()]
            }
            setFilesToBeUploaded(filesArray)
        }
    };

    function removeLastDotAndAfter(str) {
        const lastDotIndex = str.lastIndexOf('.');
        if (lastDotIndex === -1) {
            return str;
        }
        return str.slice(0, lastDotIndex);
    }

    const createIcon = (file) => {
        if (file.type.includes("video")) {
            return "file-video"
        } else if (file.type.includes("pdf")) {
            return "file-pdf"
        } else if (file.type.includes("word")) {
            return "file-word"
        } else if (file.type.includes("excel")) {
            return "file-excel"
        } else {
            return "file-lines"
        }
    };

    return (
        <PopupBaseComponent>
            <PopupBodyComponent style={{ width: '4579px', height: '1460px' }} type="large">
                <LeftBodyComponent style={{ width: '1167px' }} image={filesAdd} type="Files" subtitle="Add files to:" title={selectedParentForFiles.name} description="Upload local files or search and select from cloud DOCS to add to the folders. You can include various file types to enhance project resources and collaboration." />
                <ClosePageButtonComponent setClose={() => setAddFiles(false)} />
                <RightBodyComponent flex={true}>
                    <div className={styles.localFiles} >
                        <label className={styles.localFilesLabel}> Add local Files </label>
                        <input
                            ref={inputFileRef}
                            type="file"
                            multiple
                            onChange={onFileChange}
                            className={styles.input}
                            style={{ display: 'none' }}
                        />
                        <DarkButtonComponent style={{ position: 'absolute', top: '219.5px', left: '1304.5px', width: '502px', height: '82px' }} onClick={handleBrosweButtonClick}>
                            <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas["folder-open"]}/>
                            Browse Files
                        </DarkButtonComponent>

                        <div className={styles.orLine}></div>
                        <LightButtonComponent style={{ position: 'absolute', bottom: '79.5px', left: '1283.5px', width: '329px', height: '82px'}} onClick={handleClickAdd}>
                            <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['plus']} /> Add
                        </LightButtonComponent>
                        <DarkButtonComponent style={{ width: '377px', height: '82px', left: '1692.5px', bottom: '79.5px', position: 'absolute'}} onClick={handleClose} >
                            <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['xmark']} /> Cancel
                        </DarkButtonComponent>
                        <div className={styles.dragDropZone} onDragOver={handleDragOver} onDrop={handleDrop}>
                            {filesToBeUploaded.length === 0 && <label className={styles.dragDropLabel}>Drag and drop local files here</label>}
                            {filesToBeUploaded.length > 0 && filesToBeUploaded.map((file, index) => <div className={styles.inputName} >
                                
                                <div className={styles.imgDiv}>
                                    {!file.type.includes("image") && <FontAwesomeIcon className={styles.img} icon={byPrefixAndName.fas[createIcon(file)]} />}
                                    {file.type.includes("image") &&<img src={file.previewURL} alt={file.newName} className={styles.img}/>}
                                </div>
                                <InputTextComponent ref={fileRefs.current[index]} styleType='POPUP' width="1300px" fontSize="30px" keyboardRef={keyboardRef} 
                                value={removeLastDotAndAfter(file?.newName)} placeHolder={language.add_files["File name"]}/>
                            </div>)}
                        </div>
                        <div className={styles.orLine} style={{ top: '1249.5px' }}></div>
                        <div className={styles.verLine}></div>

                        <label className={styles.docsFilesLabel}>Add DOCS files</label>
                        <div className={styles.buttonsDiv}>
                            <InputTextComponent position='absolute' ref={inputRef} width='801' fontSize="30" keyboardRef={keyboardRef} value="" styleType='SEARCHPOPUP' 
                            placeHolder={language.add_participants.Search} onTextChanged={onTextChanged} setIsOnFocus={setIsOnFocus}/>
                            <FontAwesomeIcon className={styles.iconSearch} icon={byPrefixAndName.fal['magnifying-glass']} style={{color: isOnFocus ? "#33FFC8" : ""}}/>
                        </div>
                        <div className={styles.selectListDiv}>
                            <CustomDropdownComponent options={optionsTypes} currentChoice={currentChoice} setCurrentChoice={setCurrentChoice} width={573} />
                        </div>
                        <div className={styles.divCheckBox}>
                            <div className={styles.tableHeader}>
                                <CheckboxComponent text={language.add_participants['Select All']} checked={isCheckAll} onChange={handleSelectAll} />
                                <div className={styles.centerElement}>Type</div>
                            </div>

                            <ElementTableComponent isFiles='true' docs={docs} isCheck={isCheck} onChange={handleClickSelect} style={{ paddingLeft: 0, marginTop: 5 }} />
                        </div>
                    </div>
                </RightBodyComponent>
                {/* <KeyboardComponent visible ref={keyboardRef} x="550" y="180" width="750" height="450" s="1.8" /> */}
            </PopupBodyComponent>

        </PopupBaseComponent>
    );

}

export default AddFilesComponent;