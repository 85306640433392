import style from './TagComponent.module.css'


const TagComponent = ({ status, text }) => {
    const getBgColor = () => {
        switch (status) {
            case "active":
                return "rgba(255, 201, 21, 0.2)";
            case "inactive":
                return "rgba(198, 198, 198, 0.2)";
            case "Conference":
                return "#6CEA7F33"
            case "Meeting":
                return "#E56ADB33"
            default:
                return "rgba(51, 255, 200, 0.2)";
        }
    };

    const getColor = () => {
        switch (status) {
            case "active":
                return "#FFC915";
            case "inactive":
                return "#C6C6C6";
            case "Conference":
                return "#6CEA7F"
            case "Meeting":
                return "#E56ADB"
            default:
                return "#33FFC8";
        }
    };

    return (
        <div className={style.tag} style={{ backgroundColor: getBgColor(), color: getColor() }}>
            <div className={style.tagText}>{text.toUpperCase()}</div>
        </div>
    );

}

export default TagComponent;