import { useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../../../contexts/globalContext";

import PD_BaseListComponent from '../PD_BaseListComponent';
import PD_ConferenceComponent from './PD_ConferenceComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'

import DarkButtonComponent from '../../commons/DarkButtonComponent';
import styles from './PD_ConferenceListComponent.module.css';
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';



const ConferenceListComponent = () => {

    const { language, selectedProject, setAddParticipants } = useContext(GlobalContext);
    const { setCreateConference, setCreateMeeting } = useContext(ProjectDetailsContext)


    const [sortedConferences, setSortedConferences] = useState(selectedProject.conferences.sort((a, b) => new Date(b.scheduleDate) - new Date(a.scheduleDate)))

    useEffect(() => {
        setSortedConferences(selectedProject.conferences.sort((a, b) => new Date(b.scheduleDate) - new Date(a.scheduleDate)))
    }, [selectedProject.conferences])

    const meetingStyle = {
        background: "#FF81F51A 0% 0% no-repeat padding-box",
        border: "2px solid #C864C6",
        borderRadius: "8px",
        width: "525px",
        height: "78px",
        color: "#E56ADB",
        marginLeft: -45, 
        marginBottom: 57 
    }

    const confStyle = {
        background: "#E1FF3B1A 0% 0% no-repeat padding-box",
        border: "2px solid #B5CC35",
        borderRadius: "8px",
        width: "570px",
        height: "78px",
        color: "#6CEA7F",
        marginLeft: 25, 
        marginBottom: 57 
    }

    return (

        <PD_BaseListComponent
            title={language.project_details.Conferences}
            list={sortedConferences?.map((conference) => (
                <PD_ConferenceComponent key={conference._id} conference={conference} />
            ))}
            button={sortedConferences?.length > 0 &&
                <div>
                    <DarkButtonComponent onClick={() => setCreateMeeting(true)} style={meetingStyle}>
                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['users-rectangle']} />
                        Add meeting
                    </DarkButtonComponent>
                    <DarkButtonComponent onClick={() => setCreateConference(true)} style={confStyle}>
                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['keynote']} />
                        Add conference
                    </DarkButtonComponent>
                </div>

            }
        />

    );

}

export default ConferenceListComponent;