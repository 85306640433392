import { useContext, useEffect, useRef, useState } from 'react';

import styles from './ConferenceScreenComponent.module.css'

import { GlobalContext } from "../../contexts/globalContext";

const WaitingForJoiningScreen = ({ SetWaitingForJoining }) => {

    const { currentParticipant, currentConference, conferenceSocketRef, setCurrentConference } = useContext(GlobalContext)
    const [showStartConferenceForm, setShowStartConferenceForm] = useState(false);
    const [showJoinButton, setShowJoinButton] = useState(false);

    const inputCoderef = useRef();

    useEffect(() => {

        conferenceSocketRef.current.on('CONFERENCE_STARTED', async (conference) => {
            console.log("CONFERENCE_STARTED", conference);
            setCurrentConference(conference);
            setShowJoinButton(true);
        })


        if (currentConference.status == "New") {
            if (currentConference.topics[0].moderator._id == currentParticipant._id) {
                setShowStartConferenceForm(true);
            }
        } else {
            setShowJoinButton(true);
        }

        return () => {
            conferenceSocketRef.current.off('CONFERENCE_STARTED')
        }
    }, [])

    const StartConference = () => {
        console.log("xxxx");
        console.log(conferenceSocketRef.current.id);
        conferenceSocketRef.current.emit("START_CONFERENCE", currentConference._id, inputCoderef.current.value, (data) => {
            if (data.error) {
                console.log(data.response);
            } else {
                setShowStartConferenceForm(false);
                SetWaitingForJoining(false)
            }
        });
    }

    return (
        <div className={styles.component} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", flexWrap: "" }}>
            <div style={{ color: "white", fontSize: "80px" }}>
                {currentParticipant.name}
            </div>
            {showStartConferenceForm && <div style={{ marginTop:"40px", display: "flex", flexDirection: "column", backgroundColor: "red",  justifyContent: "center", alignItems: "center", flexWrap: "" }}>
                <div style={{ color: "white", fontSize: "40px" }}>
                    Enter the code to start de conference
                </div>
                <div style={{ color: "white", fontSize: "40px" }}>
                    <input ref={inputCoderef} type="text" style={{ color: "black", fontSize: "60px" }} placeholder='Enter code' />
                    <button type="text" onClick={() => StartConference()} style={{ padding: "10px 40px", fontSize: "60px" }}>START</button>
                </div>
            </div>}

            {showJoinButton && <div>

                <button type="text" onClick={() => SetWaitingForJoining(false)} style={{ padding: "0px 20px", fontSize: "60px" }}>Join</button>

            </div>}
        </div>
    );
}

export default WaitingForJoiningScreen;