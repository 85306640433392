import { useState, useRef, createContext } from "react"

export const AddConferenceContext = createContext([{}, () => {}])

export const AddConferenceProvider = ( props ) => {
    const currentDate = new Date();
    const calendarRef = useRef();
    const initialHourEndRef = useRef()
    const initialHourStartRef = useRef()
    const selectedHourRef = useRef()
    const titleRef = useRef()
    const keyboardRef = useRef()
    const dataChanged = useRef(false)
    const hasIntersection = useRef()
   
    const [isConfNameOnFocus, setIsConfNameOnFocus] = useState(false)
    const [dayConferences, setDayConferences] = useState([])
    const [unsaved, setUnsaved] = useState(false)
    const [save, setSave] = useState(false)
    const [dataChangedLocal, setDataChangedLocal] = useState(false)
    const [selectedRooms, setSelectedRooms] = useState([])

    const [conferenceDetailsForParticipants, setConferenceDetailsForParticipants] = useState()

    return (
        <AddConferenceContext.Provider value={{
            currentDate,
            calendarRef,
            initialHourEndRef,
            initialHourStartRef,
            selectedHourRef,
            titleRef,
            keyboardRef,
            dataChanged,
            hasIntersection,
            isConfNameOnFocus, setIsConfNameOnFocus,
            dayConferences, setDayConferences,
            unsaved, setUnsaved,
            save, setSave,
            dataChangedLocal, setDataChangedLocal,
            selectedRooms, setSelectedRooms,
            conferenceDetailsForParticipants, setConferenceDetailsForParticipants
        }}>
            {props.children}
        </AddConferenceContext.Provider>
    )
}