import { useRef, useContext, useState, useEffect, createRef } from 'react';

import { GlobalContext } from "../../contexts/globalContext";
import { ProjectDetailsContext } from '../../contexts/projectDetailsContext';

import styles from './AddTopicsComponent.module.css'
import uuid from 'react-uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import InputTextComponent from '../keyboard/InputTextComponent';
// import KeyboardComponent from '../keyboard/KeyboardComponent';
import PopupBodyComponent from './common/PopupBodyComponent';
import PopupBaseComponent from './common/PopupBaseComponent';
import ClosePageButtonComponent from '../commons/ClosePageButtonComponent'

import topicsAdd from '../../graphics/Popups/IconTopicsAdd.png'
import LightButtonComponent from '../commons/LightButtonComponent';
import LeftBodyComponent from './common/LeftBodyComponent';
import RightBodyComponent from './common/RightBodyComponent';
import FooterButtonsComponent from './common/FooterButtonsComponent';
import OutsideGlowComponent from './common/OutsideGlowComponent';

import KeyboardComponent from '../keyboard/KeyboardComponent';


const AddTopicsComponent = () => {

    const nameRef = useRef()
    const keyboardRef = useRef()
    const subtopicRef = useRef()
    const subtopicInputRef = useRef()

    const [selectedSubtopic, setSelectedSubtopic] = useState()
    // const [currentSubtopicList, setCurrentSubtopicList] = useState([])
    const [topicIsOnFocus, setTopicIsOnFocus] = useState(false)
    const [indexSubtopic, setIndexSubtopic] = useState(-1)

    const [subtopicIsOnFocus, setSubtopicIsOnFocus] = useState(false)
    const [subtopicMapIsOnFocus, setSubtopicMapIsOnFocus] = useState(false)

    const { language, selectedProject, socketRef } = useContext(GlobalContext);
    const { setAddTopic, editTopic, setEditTopic } = useContext(ProjectDetailsContext);

    const [currentSubtopicList, setCurrentSubtopicList] = useState(editTopic && editTopic.subtopics ? editTopic.subtopics :[])


    const fileRefs = useRef(currentSubtopicList.map(() => createRef()));




    function handleTopicAdd(){
        let subtopicsList = []
        subtopicsList = currentSubtopicList.map(subtopic => subtopic.name)
        socketRef.current.emit("PROJECT_UPDATE_FOLDER",
            { projectId: 
                selectedProject._id, 
                topicId: editTopic ? editTopic._id : null, 
                newData: {
                    name: nameRef.current?.value ? nameRef.current?.value : "Default Title", 
                    subtopics: subtopicsList
                } 
            }
        );
        setAddTopic()
        setEditTopic()
    }

    function handleSubtopicAdd(){
        let subtopicName = subtopicRef.current?.value ? subtopicRef.current?.value : "Default Title"
        setCurrentSubtopicList(currentSubtopicList => [...currentSubtopicList, {name: subtopicName, _id: uuid()}])
        fileRefs.current = [...fileRefs.current, createRef()]
        subtopicRef.current.value = ""
    }

    function handleDeleteSubtopic(id){
        if(editTopic){
            socketRef.current.emit("PROJECT_DELETE_SUBFOLDER", id, editTopic._id, selectedProject._id)
        } 
        let subtopicsList = currentSubtopicList
        let filteredSubtopicsList = subtopicsList.filter((subtopic) => subtopic._id !== id); 
        setCurrentSubtopicList(filteredSubtopicsList)
    }

    useEffect(() => {
        if(indexSubtopic !== -1 && !subtopicMapIsOnFocus){
            let updatedSubtopics = currentSubtopicList.map((subtopic) => {
                if (subtopic._id === currentSubtopicList[indexSubtopic]._id) {
                    return {

                        ...subtopic, name: fileRefs.current[indexSubtopic].current?.value ? fileRefs.current[indexSubtopic].current?.value : "Default Title"
                    };
                }
                return subtopic;
            })
            setCurrentSubtopicList(updatedSubtopics)
        }
    }, [subtopicMapIsOnFocus]);

    function deleteTopic(){
        socketRef.current.emit("PROJECT_DELETE_FOLDER", editTopic._id, selectedProject._id, () => {
            setEditTopic()
        })

    }

    function closePopup(){
        setAddTopic()
        setEditTopic()
    }



    
    return (
        <PopupBaseComponent>
            <KeyboardComponent visible ref={keyboardRef} x="1519" y="1480" width="2082px" height="651px" s="1" />
            <PopupBodyComponent style={{width: 2760, height: 1040}}>
                <ClosePageButtonComponent setClose={closePopup} />
                <LeftBodyComponent image={topicsAdd} title="New folder and subfolders" description="Enter the details for new folder and subfolder to structur your project accordingly."/>
                <RightBodyComponent>
                    <div className={styles.body_header} >
                        Folder
                        {/* {editTopic && 
                            <ButtonComponent text={language.add_topics.Delete} style={{height: 75, width: 300}} onClick={() => deleteTopic()}/>
                        } */}
                    </div>
                    {topicIsOnFocus && <OutsideGlowComponent style={{top: 220, left: 1232}}/>}
                    <InputTextComponent ref={nameRef} keyboardRef={keyboardRef} value={editTopic ? editTopic.name : ""} setIsOnFocus={setTopicIsOnFocus} placeHolder="Enter folder name here" styleType='POPUP'fontSize="40px" width="1560px"/>
                    {!topicIsOnFocus && <FontAwesomeIcon className={styles.iconPenTopic} icon={byPrefixAndName.fal['pen']} /> }
                    <div className={styles.subtopics}>
                        <div className={styles.subtopicsHeader}>
                            Subfolders
                            <div className={styles.subtopicsOptional}>(Optional)</div>
                        </div>
                        
                        <div className={styles.subtopics_map}>
                           
                            {currentSubtopicList.length > 0 && currentSubtopicList.map((subtopic, index) => 
                            <div key={index} className={styles.subtopicsAdd}>
                                {indexSubtopic === index && subtopicMapIsOnFocus  && <OutsideGlowComponent style={{top: -8, left: -8, width: 1240 }}/>}

                                <InputTextComponent onChange ref={fileRefs.current[index]} keyboardRef={keyboardRef} setIndex={setIndexSubtopic} index={index} 
                                setIsOnFocus={setSubtopicMapIsOnFocus} value={subtopic.name} placeHolder="Enter subfolder name here" styleType={'POPUP'} 
                                fontSize="40px" width={indexSubtopic === index && subtopicMapIsOnFocus ? "1200px": "1520px"}/>
                                <LightButtonComponent onClick={() => handleDeleteSubtopic(subtopic._id)} style={{width:300}}>
                                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['trash']} /> 
                                    Delete
                                </LightButtonComponent>
                                    
                                {!subtopicMapIsOnFocus && <FontAwesomeIcon className={styles.iconEditElement} icon={byPrefixAndName.fal['pen']} /> }
                                
                            </div>)}
                            <div className={styles.subtopicsAdd}>
                                {subtopicIsOnFocus && <OutsideGlowComponent style={{top: -8, left: -8, width: 1240 }}/>}
                                <InputTextComponent ref={subtopicRef} keyboardRef={keyboardRef} setIsOnFocus={setSubtopicIsOnFocus} value="" placeHolder="Enter subfolder name here" styleType={'POPUP'} fontSize="40px" width={subtopicIsOnFocus ? "1200px": "1520px"}/>
                                <LightButtonComponent onClick={handleSubtopicAdd} style={{width:subtopicIsOnFocus?300:0}}>
                                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['plus']} /> 
                                    Add
                                </LightButtonComponent>
                                {!subtopicIsOnFocus && <FontAwesomeIcon className={styles.iconPen} icon={byPrefixAndName.fal['pen']} />}

                            </div>
                        </div>
                    </div>
                    <FooterButtonsComponent lightText={"Create"} lightClick={handleTopicAdd} darkClick={closePopup}/>
                    
                </RightBodyComponent>
            </PopupBodyComponent>
            
        </PopupBaseComponent>
    );

}

export default AddTopicsComponent;