import { useContext, useEffect } from 'react';
import { GlobalConfigContext } from "../contexts/globalConfigContext";

import SocketManagerConfig from '../utils/SocketManagerConfig';
import CFG_ASideMenuComponent from './CFG_ASideMenuComponent';
import CFG_HeaderComponent from './CFG_HeaderComponent';
import CFG_ErrorComponent from './CFG_ErrorComponent';

import CFG_DeparmentsPage from './pages/CFG_DeparmentsPage';
import CFG_FunctionsPage from './pages/CFG_FunctionsPage';
import CFG_AdminsPage from './pages/CFG_AdminsPage';

import CFG_LocationsPage from './pages/CFG_LocationsPage';
import CFG_RoomsPage from './pages/CFG_RoomsPage';

const CFG_MainScreenComponent = () => {

    const { currentPage, setCurrentPage, currentError } = useContext(GlobalConfigContext);
    useEffect(() => {
        setCurrentPage("Departments");
    }, []);

    return (
        <>
            <SocketManagerConfig />

            <CFG_HeaderComponent></CFG_HeaderComponent>

            <CFG_ASideMenuComponent></CFG_ASideMenuComponent>

            <main id="main" className="main">
                {currentError && <CFG_ErrorComponent></CFG_ErrorComponent>}

                {currentPage == "Departments" && <CFG_DeparmentsPage></CFG_DeparmentsPage>}
                {currentPage == "Functions" && <CFG_FunctionsPage></CFG_FunctionsPage>}
                {currentPage == "Admins" && <CFG_AdminsPage></CFG_AdminsPage>}

                {currentPage == "Locations" && <CFG_LocationsPage></CFG_LocationsPage>}
                {currentPage == "Rooms" && <CFG_RoomsPage></CFG_RoomsPage>}
            </main>
        </>
    );
}

export default CFG_MainScreenComponent;