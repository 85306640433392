import { useRef, useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../contexts/globalContext";
import en from "../langs/en.json";
import ro from "../langs/ro.json";
import { ApiLoginUser } from '../utils/RestServices';
import { ApiGetNextOrActiveConference } from '../utils/RestServices';

import Lottie from 'react-lottie-player';

import styles from './LoginComponent.module.css';

import KeyboardComponent from './keyboard/KeyboardComponent';
import InputTextComponent from './keyboard/InputTextComponent';
import NotificationComponent from './commons/NotificationComponent';

import video from '../graphics/big wave fundal loop_6.mp4';

import LightButtonComponent from './commons/LightButtonComponent';

import enImg from '../graphics/en.svg';
import roImg from '../graphics/ro.svg';

import arrowLeft from '../graphics/arrow-left-animation.json';

const LoginComponent = () => {

    const ref = useRef();
    const inputGuestNameRef = useRef();
    const inputVisitorNameRef = useRef();
    const inputUserNameRef = useRef();
    const inputPasswordRef = useRef();
    const keyboardRef = useRef();
    const timerForStartingConference = useRef();

    const [styleType, setStyleType] = useState('POPUP');
    const [passErrorMessage, setPassErrorMessage] = useState();
    const [errorBothMessage, setErrorBothMessage] = useState(false);

    const { deskData, language, setLanguage, setCurrentUser, guestLogin, userLogin, appHeight, appWidth, setCurrentConference, setCurrentParticipant } = useContext(GlobalContext);



    function setGuestData() {
        if (guestLogin) {
            var body = JSON.stringify(guestLogin);
            ApiLoginUser(body, data => {
                console.log("token", data.token);
                CloseTimeoutForStartingConference();
                setCurrentUser(data);
            });
        }
    }

    function setUserData() {
        if (inputVisitorNameRef.current.value) {
            // If visitor name is filled, log in as visitor
            var body = JSON.stringify({
                name: inputVisitorNameRef.current.value
            });

            ApiLoginUser(body, data => {
                if (data._id) {
                    CloseTimeoutForStartingConference();
                    setCurrentUser(data);
                } else {
                    setPassErrorMessage("Login error: user data or password wrong");
                }
            });
        } else {
            // If email/password is filled, log in as user
            var body = JSON.stringify({
                email: inputUserNameRef.current.value,
                password: inputPasswordRef.current.value
            });

            ApiLoginUser(body, data => {
                if (data._id) {
                    CloseTimeoutForStartingConference();
                    setCurrentUser(data);
                } else {
                    setPassErrorMessage("Login error: user data or password wrong");
                }
            });
        }
    }

    // Handle visitor input click
    const handleVisitorInputClick = () => {
        if (inputUserNameRef.current.value || inputPasswordRef.current.value) {
            setErrorBothMessage(true);  // Display error if both fields are in use
        } else {
            setErrorBothMessage(false); // Clear error if visitor name is clicked properly
        }
    };

    // Handle user input click (email or password)
    const handleUserInputClick = () => {
        if (inputVisitorNameRef.current.value) {
            setErrorBothMessage(true);  // Display error if visitor name is also filled
        } else {
            setErrorBothMessage(false); // Clear error if user fields are clicked properly
        }
    };

    const handleClose = () => {
        setErrorBothMessage(false);
        setPassErrorMessage(false)
    };

    const CloseTimeoutForStartingConference = () => {
        if (timerForStartingConference.current) {
            clearTimeout(timerForStartingConference.current);
            timerForStartingConference.current = null;
        }
    }

    useEffect(() => {
        if (deskData) {
            //console.log("desk id", deskData._id);
            var body = JSON.stringify({
                deskId: deskData._id
            });

            ApiGetNextOrActiveConference(body, data => {
                if (data) {
                    console.log("aduc urmatoarea conferinta");
                    CloseTimeoutForStartingConference();
                    timerForStartingConference.current = setTimeout(() => {
                        setCurrentParticipant(data.participant);
                        setCurrentConference(data.conference);
                    }, data.conference.millisUntilStart);
                }
            });
        }
    }, [deskData])

    return (
        <div ref={ref} className={styles.component} style={{ height: `${appHeight}px`, width: `${appWidth}px` }}>



            <video id="background-video" autoPlay loop playsInline muted style={{ height: `${appHeight}px`, width: `${appWidth}px` }}>
                <source src={video} type="video/mp4" />
            </video>
            <KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2082 - 60) / 2} y={appHeight * 2 / 3} width="2082px" height="651px" s="1" position="relative" />
            {passErrorMessage &&
                <NotificationComponent onClose={handleClose} status={'ERROR'} text={'Invalid Email or Password! The email or password you entered is incorrect. Please try again.'}></NotificationComponent>
            }
            {errorBothMessage &&
                <NotificationComponent onClose={handleClose} status={'ERROR'} text={'Choose one option! Please log in with email and password or as a visitor, not both.'}></NotificationComponent>
            }
            <div className={styles.inputFields}>
                <div className={styles.languageChoices}>
                    <img src={enImg} className={styles.languageButton} onClick={() => setLanguage(en)} style={{ border: language == en ? "4px solid #FFFFFF" : "" }} />
                    <img src={roImg} className={styles.languageButton} onClick={() => setLanguage(ro)} style={{ border: language == ro ? "4px solid #FFFFFF" : "" }} />
                </div>
                <div className={styles.headerText}>Welcome</div>
                {!passErrorMessage && <div className={styles.headerDetails}>{language.login_page.Please}</div>}


                <div className={styles.loginChoices}>
                    {guestLogin && <div className={styles.guestLogin}>
                        <InputTextComponent ref={inputGuestNameRef} keyboardRef={keyboardRef} value={guestLogin.name} fontSize="40px"
                            placeHolder="Name" styleType={styleType} width="1500px" height="120px" />
                        <LightButtonComponent onClick={setGuestData} style={{ width: 281, height: 82, marginTop: -50 }}>
                            <Lottie
                                loop={true}
                                play={true}
                                animationData={arrowLeft}
                                style={{ position: "absolute", width: 60, height: 35, left: 50 }}
                            />
                            <div className={styles.loginPos}> Login</div>
                        </LightButtonComponent>
                    </div>}
                    {!guestLogin && <div className={styles.guestLogin}>
                        <div className={styles.visitorDiv}>
                            <div className={styles.lineVisitor}></div>
                            <label className={styles.textChoice} style={{ width: 674 }}> Login with an email and password </label>
                        </div>
                        <InputTextComponent ref={inputUserNameRef} keyboardRef={keyboardRef} value={userLogin ? userLogin.email : ""} fontSize="40px" onClick={handleUserInputClick}
                            placeHolder="Email" styleType='POPUP' width="1500px" height="120px" border={passErrorMessage || errorBothMessage ? "2px solid #FF1F1F" : ""} />
                        <InputTextComponent ref={inputPasswordRef} type="password" keyboardRef={keyboardRef} value="" placeHolder={language.login_page.Password} fontSize="40px"
                            onClick={handleUserInputClick} border={passErrorMessage || errorBothMessage ? "2px solid #FF1F1F" : ""} styleType='POPUP' width="1500px" height="120px" />

                        <div className={styles.visitorDiv}>
                            <div className={styles.lineVisitor}></div>
                            <label className={styles.textChoice}> Login as visitor </label>
                        </div>

                        <InputTextComponent ref={inputVisitorNameRef} keyboardRef={keyboardRef} value="" placeHolder={language.login_page.Name} onClick={handleVisitorInputClick}
                            fontSize="40px" styleType='POPUP' width="1500px" height="120px" border={passErrorMessage || errorBothMessage ? "2px solid #FF1F1F" : ""} />

                        <LightButtonComponent onClick={setUserData} style={{ width: 281, height: 82, bottom: 80, position: 'absolute', left: '50%', transform: 'translate(-50%, 0%)' }}>
                            <Lottie
                                loop={true}
                                play={true}
                                animationData={arrowLeft}
                                style={{ position: "absolute", width: 60, height: 35, left: 50 }}
                            />
                            <div className={styles.loginPos}> Login</div>
                        </LightButtonComponent>
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default LoginComponent;
