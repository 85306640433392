import { useContext, useRef, useState} from 'react';

import { GlobalContext } from "../contexts/globalContext";

import styles from './ProjectsListComponent.module.css'

import ProjectDetailsComponent from './ProjectDetailsComponent';
import HeaderComponent from './commons/HeaderComponent';
import ProjectElementComponent from './projectList/ProjectElementComponent';
import SocketManager from '../utils/SocketManager';
import NotificationComponent from './NotificationComponent';
import UserDetailsComponent from './UserDetailsComponent';
import { ProjectDetailsProvider } from "../contexts/projectDetailsContext"

import video from '../graphics/big wave fundal loop_6.mp4'
import NewProjectPopup from './projectDetails/NewProjectPopup';


const ProjectsListComponent = () => {
    const ref = useRef()

    const [currentProjectList, setCurrentProjectList] = useState([])
    const [seeNotification, setSeeNotification] = useState(false)

    const { selectedProject, seeUserDetails, appWidth, appHeight, currentUser, createNewProject} = useContext(GlobalContext);


    return (
        <div>
            <SocketManager/>
            {!selectedProject && <div className={styles.component} style={{ height: `${appHeight}px`, width: `${appWidth}px`, color: "green"}} >
                <video id="background-video" autoPlay loop playsInline muted style={{ height: `${appHeight}px`, width: `${appWidth}px`}}>
                    <source src={video} type="video/mp4"/>
                </video>
                <HeaderComponent currentProjectList={currentProjectList} setCurrentProjectList={setCurrentProjectList} setSeeNotification={setSeeNotification} seeNotification={seeNotification}/>
                {seeNotification && <NotificationComponent/>}
                {currentProjectList.length < 5 ?
                    <div className={styles.list}>
                        {currentProjectList.map((project, index) => (
                            <ProjectElementComponent project={project} key={project._id} index={index} seeNotification={seeNotification}/>
                        ))}
                    </div>
                    : 
                    <div ref={ref} className={styles.overflowing_list} style={{width:appWidth}}>
                        {currentProjectList.map((project, index) => (
                            <ProjectElementComponent project={project} key={project._id} index={index} seeNotification={seeNotification}/>
                        ))}
                    </div>

                }
                
            </div>}
            {!selectedProject && createNewProject && <NewProjectPopup/>}
            {seeUserDetails && <UserDetailsComponent/>}
            {!selectedProject && seeNotification && <div className={styles.bottomShadow} />}
            {!selectedProject && <div className={styles.leftShadow} style={{height: appHeight - 198}}/>}
            {!selectedProject && <div className={styles.rightShadow} style={{height: appHeight - 198}}/>}
            {selectedProject && <ProjectDetailsProvider><ProjectDetailsComponent /></ProjectDetailsProvider>}
        </div>
    );

}

export default ProjectsListComponent;