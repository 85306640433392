import { useContext } from 'react';

import './custom.css'

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import PD_BaseListComponent from '../PD_BaseListComponent';
import PD_TopicComponent from './PD_TopicComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'

import styles from './PD_TopicListComponent.module.css';
import DarkButtonComponent from '../../commons/DarkButtonComponent';


const TopicListComponent = () => {
    const { selectedProject } = useContext(GlobalContext);
    const { setAddTopic } = useContext(ProjectDetailsContext);


    return (
        <PD_BaseListComponent
            title = {"Folders"}
            list = {selectedProject.topics?.map((topic) => (
                <PD_TopicComponent key={topic._id} topic={topic}/>
            ))}
            button = {selectedProject.topics?.length > 0 &&
                <DarkButtonComponent onClick={() => setAddTopic(true)} style={{width: 1120, marginLeft: -40, marginBottom: 57, background: "#33FFC81A 0% 0% no-repeat padding-box" }}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['folder']} />
                    Add folder
                </DarkButtonComponent>
            }
        />
       

    );

}

export default TopicListComponent;