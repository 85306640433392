import { useRef, useContext, useState, useEffect } from 'react';
import styles from './ManageParticipantsComponent.module.css'

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';
import { AddConferenceContext } from '../../../contexts/addConferenceContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import bigIcon from '../../../graphics/Popups/img-arrange-part.png'
import InputTextComponent from '../../keyboard/InputTextComponent';
import InitialsCircleComponent from '../../commons/InitialsCircleComponent';
import { GetMaxZIndex } from '../../../utils/UtilitaryFunctions';
import DarkButtonComponent from '../../commons/DarkButtonComponent';

import CornerImage from "../../../graphics/config/colt.png"
import DeskHImage from "../../../graphics/config/masa-h.png"
import DeskVImage from "../../../graphics/config/masa-v.png"


const ManageParticipantsComponent = () => {
    const inputRef = useRef()
    const keyboardRef = useRef()
    const participantsFilterSearchRef = useRef()
    const allParticipantsRef = useRef()

    const [selectedFilter, setSelectedFilter] = useState("All")

    const [displayedParticipants, setDisplayedParticipants] = useState([])
    const [attachedParticipants, setAttachedParticipants] = useState([])
    const [allDisplayedDesks, setAllDisplayedDesks] = useState([])


    const [displayedRoom, setDisplayedRoom] = useState()
    const [deskHeight, setDeskHeight] = useState()

    const [allRooms, setAllRooms] = useState([])



    const { language, appWidth, socketRef, selectedProject } = useContext(GlobalContext);
    const { conferenceDetailsForParticipants } = useContext(AddConferenceContext);

    const filterOptions = [
        "All", "Members", "Guests", "Partners"
    ]

    useEffect(() => {
        // de verificat
        socketRef.current.emit("GET_CONFERENCE_PARTICIPANTS_TO_ADD", selectedProject._id, conferenceDetailsForParticipants._id, callback => {
            allParticipantsRef.current = callback
            setDisplayedParticipants(callback)
        })

        socketRef.current.emit('GET_CONFERENCE_ROOMS_BY_ID', conferenceDetailsForParticipants.rooms, callback => {
            setAllRooms(callback)
            setDisplayedRoom(callback[0])

            var newDskHeight = 70;
            var mxx = Math.max(callback[0].desks_east?.length, callback[0].desks_west?.length);

            if (mxx > 0) {
                var discac = Math.trunc(900 / (mxx + 1.5));

                if (discac > 0) {
                    newDskHeight = discac;
                }
            }

            setDeskHeight(newDskHeight);
        })



    }, [])

    useEffect(() => {
        if (displayedRoom?._id) {
            // am nevoie de populate pe attachedparticipant
            socketRef.current.emit("CONFERENCE_GET_ATTACHED_PARTICIPANTS_FROM_ROOM", { 
                conferenceId: conferenceDetailsForParticipants._id, 
                roomId: displayedRoom._id }, 
                callback => {
                    if (!callback.error) {
                        console.log(callback.response)
                        setAttachedParticipants(callback.response)
                    } else {
                        console.log(callback.response)
                    }

            })
            setAllDisplayedDesks(getAllDesks())
        }

    }, [displayedRoom])

    useEffect(() => {
        if (allParticipantsRef.current) {
            let result = []
            if (inputRef.current.value) {
                result = allParticipantsRef.current.filter(participant => participant.name?.toLowerCase().includes(inputRef.current.value.toLowerCase()));
            } else {
                result = allParticipantsRef.current
            }

            let value = selectedFilter
            value = value.toUpperCase().replace(/S$/, '');

            if (value !== "ALL") {
                if (value === "MEMBER")
                    result = result.filter(participant => participant.role.includes(value) || participant.role.includes('ADMINISTRATOR'));
                else
                    result = result.filter(participant => participant.role.includes(value));
            }
            setDisplayedParticipants(result)
        }
    }, [inputRef.current?.value])

    const getAllDesks = () => {
        const { desks_east, desks_north, desks_south, desks_west } = displayedRoom;

        const allDesks = [
            ...desks_east.map((desk) => ({
                _id: desk._id,
                number: parseInt(desk.number, 10),
                partOf: 'desks_east',
            })),
            ...desks_north.map((desk) => ({
                _id: desk._id,
                number: parseInt(desk.number, 10),
                partOf: 'desks_north',
            })),
            ...desks_south.map((desk) => ({
                _id: desk._id,
                number: parseInt(desk.number, 10),
                partOf: 'desks_south',
            })),
            ...desks_west.map((desk) => ({
                _id: desk._id,
                number: parseInt(desk.number, 10),
                partOf: 'desks_west',
            })),
        ];

        allDesks.sort((a, b) => a.number - b.number);
        return allDesks;
    };

    const handleFilterChange = (e) => {

        let value = e.target.innerText
        value = value.toUpperCase().replace(/S$/, '');

        let result = []
        if (value !== "ALL") {
            if (value === "MEMBER")
                result = allParticipantsRef.current.filter(participant => participant.role.includes(value) || participant.role.includes('ADMINISTRATOR'));
            else
                result = allParticipantsRef.current.filter(participant => participant.role.includes(value));

        } else {
            result = allParticipantsRef.current
        }

        if (inputRef.current.value) {
            result = result.filter(participant => participant.name?.toLowerCase().includes(inputRef.current.value.toLowerCase()));
        } else {
            result = result
        }

        setSelectedFilter(e.target.innerText)
        setDisplayedParticipants(result);


    }

    function onTextChanged() {
        setDisplayedParticipants([])
    }

    const addVisitor = () => {
        console.log("add vis")
    }

    const changeRooms = (room) => {
        setDisplayedRoom(room)
    }

    const addParticipantToRoom = (selectedParticipant) => {

        const availableDesk = allDisplayedDesks.find(
            (desk) => !attachedParticipants.some(attached => attached.desk._id === desk._id)
        );

        socketRef.current.emit("CONFERENCE_ADD_PARTICIPANT_TO_DESK",{ 
            conferenceId: conferenceDetailsForParticipants._id, 
            participantId: selectedParticipant._id, 
            deskId: availableDesk._id, 
            roomId: displayedRoom._id
        }, callback => {
            // console.log(callback)
            setAttachedParticipants(callback.response)
            // setDisplayedRoom(displayedRoom)
        })
            
    }

    useEffect(() => {
        console.log(attachedParticipants)
    }, [attachedParticipants])

    const removeParticipant = (assignedParticipant) => {
        console.log("remove", assignedParticipant)
        socketRef.current.emit("CONFERENCE_REMOVE_PARTICIPANT_FROM_DESK", { 
            attachedParticipantId: assignedParticipant._id
        }, callback => {
            // console.log(callback)
            setAttachedParticipants(callback.response)
        })
    }


    return (
        <div className={styles.component}>
            <div className={styles.leftBody}>
                <div className={styles.info}>
                    <img src={bigIcon} alt={"img"} className={styles.img} />
                    <div className={styles.title}>Arrange Participants</div>
                    <div className={styles.description}>Arrange participants in the numbered list according to their assigned spots in the rooms. You can also add remote users as needed.</div>
                </div>
                <div className={styles.participantsFilterSearch} ref={participantsFilterSearchRef}>
                    <div className={styles.filter}>
                        {filterOptions.map((option, index) => <div key={index} className={selectedFilter === option ? styles.selectedTag : styles.tag} onClick={handleFilterChange}>
                            {option}
                        </div>)}
                    </div>
                    <InputTextComponent ref={inputRef} fontSize="30" width={appWidth / 7} marginLeft="25px" keyboardRef={keyboardRef} value="" styleType='SEARCHPOPUP'
                        placeHolder={language.add_participants.Search} onTextChanged={onTextChanged} />
                    <FontAwesomeIcon className={styles.iconMagnifyingGlass} icon={byPrefixAndName.fal['magnifying-glass']} />

                </div>
                <div className={styles.participantsListAndAdd}>
                    <div className={styles.participantsList}>
                        {displayedParticipants.map((participant) => <div key={participant._id} className={styles.participantCard} onClick={() => addParticipantToRoom(participant)}>
                            <InitialsCircleComponent name={participant.name} style={{ marginLeft: 25, height: 121, width: 121, border: "6px solid #0D0032", marginBottom: 10 }} />
                            <div className={styles.participantDetails}>
                                {participant.role}
                                <div className={styles.participantName}>{participant.name}</div>
                                {participant.function?.name} {participant.function ? "|" : ""} {participant.department?.name}
                            </div>
                        </div>)}

                    </div>
                    <div className={styles.participantListShadow} style={{ zIndex: GetMaxZIndex() + 1 }} />
                    <DarkButtonComponent style={{ backgroundColor: "#2D2748", position: "relative", marginBottom: 40, width: 456 }} onClick={addVisitor}>
                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['plus']} /> Add Visitor
                    </DarkButtonComponent>
                </div>
            </div>
            <div className={styles.rightBody}>
                <div className={styles.nrParticipants}>Participants </div>
                <div className={styles.addedParticipantsList}>
                    {allDisplayedDesks.map((desk) => {
                        const assignedParticipant = attachedParticipants.find(
                            (attached) => attached.desk._id === desk._id
                        );

                        return (
                            <div className={styles.positionCard} key={desk._id} style={{opacity: assignedParticipant ? 1 : 0.4}}>
                                {assignedParticipant ? (<div className={styles.assignedParticipant} onClick={() => removeParticipant(assignedParticipant)}>
                                    <InitialsCircleComponent name={assignedParticipant.participant.name ? assignedParticipant.participant.name : "Test test"}
                                        style={{ marginLeft: 25, height: 121, width: 121, border: "6px solid #0D0032", marginBottom: 10, marginTop: 20 }} />
                                    <div className={styles.participantDetails}>
                                        {assignedParticipant.participant.role}
                                        <div className={styles.participantName}>{assignedParticipant.participant.name ? assignedParticipant.participant.name : "Test test"} </div>
                                        {assignedParticipant.participant.function?.name} {assignedParticipant.participant.function ? "|" : ""} {assignedParticipant.participant.department?.name}
                                    </div>
                                    <div className={styles.assignedDeskNumber} style={{zIndex: GetMaxZIndex() + 1}}>{desk.number}</div>
                                </div>
                                ) : (<div className={styles.numberedCircle}>{desk.number}</div>)}
                            </div>
                        );
                    })}
                </div>

                <div className={styles.roomDisplay}>
                    <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                {
                                    displayedRoom?.desks_north.length > 0 && displayedRoom?.desks_west.length > 0 && <><img alt={"img"} src={CornerImage} height={deskHeight} /></>
                                }
                                {displayedRoom?.desks_north.map((desk) => (
                                    <div key={desk.number} style={{ position: "relative" }}>
                                        <span style={{ position: "absolute", left: "40%", bottom: `${deskHeight / 12}px`, color: "white", fontSize: "60px", fontWeight: "bold" }}>{desk.number}</span>
                                        <img alt={"img"} src={DeskHImage} height={deskHeight} />
                                    </div>
                                ))}
                                {
                                    displayedRoom?.desks_north.length > 0 && displayedRoom?.desks_east.length > 0 && <img alt={"img"} src={CornerImage} height={deskHeight} style={{ transform: "rotate(90deg)" }} />
                                }
                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ width: "50%", display: "flex", justifyContent: "start" }}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        {displayedRoom?.desks_west.map((desk) => (
                                            <div key={desk.number} style={{ position: "relative" }}>
                                                <span style={{ position: "absolute", top: "40%", right: `${deskHeight / 7}px`, color: "white", fontSize: "60px", fontWeight: "bold" }}>{desk.number}</span>
                                                <img alt={"img"} src={DeskVImage} width={deskHeight} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div style={{ width: "50%", display: "flex", justifyContent: "end" }}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        {displayedRoom?.desks_east.map((desk) => (
                                            <div key={desk.number} style={{ position: "relative" }}>
                                                <img alt={"img"} src={DeskVImage} width={deskHeight} style={{ transform: "rotate(180deg)" }} />
                                                <span style={{ position: "absolute", top: "40%", left: `${deskHeight / 7}px`, color: "white", fontSize: "60px", fontWeight: "bold" }}>{desk.number}</span>
                                            </div>

                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex" }}>
                                {
                                    displayedRoom?.desks_south.length > 0 && displayedRoom?.desks_west.length > 0 && <img alt={"img"} src={CornerImage} height={deskHeight} style={{ transform: "rotate(-90deg)" }} />
                                }
                                {displayedRoom?.desks_south.map((desk) => (
                                    <div key={desk.number} style={{ position: "relative" }}>
                                        <img alt={"img"} src={DeskHImage} height={deskHeight} style={{ transform: "rotate(180deg)" }} />
                                        <span style={{ position: "absolute", left: "40%", top: `${deskHeight / 12}px`, color: "white", fontSize: "60px", fontWeight: "bold" }}>{desk.number}</span>
                                    </div>
                                ))}
                                {
                                    displayedRoom?.desks_south.length > 0 && displayedRoom?.desks_east.length > 0 && <img alt={"img"} src={CornerImage} height={deskHeight} style={{ transform: "rotate(180deg)" }} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.roomsTab}>
                    {allRooms.map((room) => <div key={room._id} className={room._id === displayedRoom._id ? styles.roomCardSelected : styles.roomCard} onClick={() => changeRooms(room)}>
                        <div style={{ fontSize: 28, lineHeight: 1 }}>Bucharest</div>
                        {room.name}
                    </div>)}

                </div>
            </div>
        </div>
    );

}

export default ManageParticipantsComponent;