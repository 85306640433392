import { GetMaxZIndex } from '../../utils/UtilitaryFunctions';
import styles from './NotificationComponent.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';
import succes from '../../graphics/mesaj succes-anim.json'
import error from '../../graphics/mesaj error-anim.json'

import Lottie from 'react-lottie-player'

const NotificationComponent = ({ status, text, onClose }) => {
    const [boldText, normalText] = text.split('!');

    return (
        <div className={styles.main} style={{
            zIndex: GetMaxZIndex() + 1, background: status === 'ERROR' ? "#FF1F20 0% 0% no-repeat padding-box" : "#27A745 0% 0% no-repeat padding-box",
            border: status === 'ERROR' ? '4px solid #FF4918' : '4px solid #6ACA69'
        }}>
            <div style={{ fontFamily: "initial", fontWeight: "initial" }}>
                <Lottie
                    loop={true}
                    play={true}
                    animationData={status === 'ERROR' ? error : succes}
                    style={{ width: 80, paddingLeft: 28, position: 'relative' }}
                />
            </div>
            <label className={styles.textNotif} style={{ zIndex: GetMaxZIndex() + 1 }}> <strong>{boldText}!</strong> {normalText}
            </label>
            <div className={styles.close} onClick={onClose}>
                <FontAwesomeIcon icon={byPrefixAndName.fas['xmark']} />
            </div>
        </div>
    );

}

export default NotificationComponent;