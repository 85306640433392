import { useContext, useEffect } from 'react';
import { GlobalConfigContext } from "../contexts/globalConfigContext";

const CFG_MainScreenComponent = () => {

    const { currentError, setCurrentError } = useContext(GlobalConfigContext);

    useEffect(() => {
        setTimeout(() => {
            setCurrentError(null);
        }, 4000);
    }, []);

    return (
        <div className="alert alert-danger alert-dismissible fade show" role="alert" style={{ position:"fixed", zIndex:"99", bottom:"-5px",right:"10px"}}>
            <h4 className="alert-heading">Error</h4>
            <hr />
            <p className="mb-0">{currentError}</p>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={ ()=> setCurrentError(null) }></button>
        </div>
    );
}

export default CFG_MainScreenComponent;