import { useContext, useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import './custom.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { GlobalContext } from "../../contexts/globalContext";

import styles from './PD_HeaderComponent.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'

import { ProjectDetailsContext } from '../../contexts/projectDetailsContext';
import InputTextComponent from '../keyboard/InputTextComponent';



const HeaderDetailsComponent = ({ seeNotification, setSeeNotification }) => {
    const editNameRef = useRef()
    const keyboardRef = useRef()

    const [clickEditName, setClickEditName] = useState()

    const { language, currentUser, selectedProject, setSelectedProject, socketRef, statusProjectList, setProjectsList, setTopicFiles, selectedTopicIdRef } = useContext(GlobalContext);

    const { setEditName } = useContext(ProjectDetailsContext);

    const [isChecked, setIsChecked] = useState(selectedProject.active);



    const handleSwitchChange = (e) => {
        setIsChecked(e.target.checked);
        socketRef.current.emit("UPDATE_PROJECT", { projectId: selectedProject._id, newData: { active: e.target.checked } },
            (project) => {
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator") {
                    let updatedActive = { active: project.active }
                    setSelectedProject(selectedProject => ({
                        ...selectedProject,
                        ...updatedActive
                    }))
                }
            }
        );
    };

    function deleteProject() {
        socketRef.current.emit("DELETE_PROJECT", selectedProject._id, (callback) => {
            // console.log(callback)
            setSelectedProject()
        });
    }

    function handleCloseProject() {
        setSelectedProject()
        selectedTopicIdRef.current = null

        setTopicFiles()
        socketRef.current.emit('GET_ALL_PROJECTS', currentUser._id, null, statusProjectList, (projects) => {
            setProjectsList(projects)
            
        });
    }



    function handleEditName() {
        if (editNameRef.current?.value !== selectedProject.name) {
            socketRef.current.emit("UPDATE_PROJECT", { projectId: selectedProject._id, newData: { name: editNameRef.current?.value ? editNameRef.current?.value : "Default Title" } },
                (project) => {
                    let updatedName = { name: project.name ? project.name : selectedProject.name }
                    setSelectedProject(selectedProject => ({
                        ...selectedProject,
                        ...updatedName
                    }))
                }
            );
        }
        setClickEditName(false)

    }

    return (
        <div style={{ flexDirection: "row", display: "flex" }}>
            <div style={{display: 'flex', flexDirection: 'row'}} onClick={handleCloseProject}>
                <FontAwesomeIcon className={styles.goBack} icon={byPrefixAndName.fal["arrow-left"]}/>
                <div className={styles.goBackText} >Back to Projects</div>
            </div>
            <div className={styles.notifications} >
                <FontAwesomeIcon icon={byPrefixAndName.fal['calendar-days']} onClick={() => setSeeNotification(!seeNotification)} />
            </div>
            <div className={styles.switchDiv} style={{color: isChecked ? "#33FFC8" : "#FFFFFF"}}>
                <div style={{color: isChecked ? "#FFFFFF" : "#33FFC8"}}>Inactive</div>
                <Form>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Active"
                        className='custom-switch'
                        checked={isChecked}
                        onChange={handleSwitchChange}
                    />
                </Form>
            </div>


            {!clickEditName && <>
                <div className={styles.titleCenterText} onClick={() => setClickEditName(true)}>{selectedProject.name}</div>
                {(currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator") &&
                    <FontAwesomeIcon className={styles.editIconClicked} style={{ left: 3060, top: 15 }} icon={byPrefixAndName.fal['pen']} onClick={() => setClickEditName(true)} />}
            </>}

            {clickEditName && <div className={styles.editName}>
                <div className={styles.editBackground} />
                <InputTextComponent ref={editNameRef} styleType='EDT' keyboardRef={keyboardRef} value={selectedProject.name} onFocusFunction={() => handleEditName()} />
                <FontAwesomeIcon className={styles.editIconClicked} icon={byPrefixAndName.fal['pen']} onClick={() => handleEditName()} />
            </div>}

        </div>

    );

}

export default HeaderDetailsComponent;