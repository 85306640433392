import { useRef, useContext, useState } from 'react';
import styles from './AddPartnersComponent.module.css'

import { GlobalContext } from "../../contexts/globalContext";
import { ProjectDetailsContext } from '../../contexts/projectDetailsContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import InputTextComponent from '../keyboard/InputTextComponent';
// import KeyboardComponent from '../keyboard/KeyboardComponent';
import PopupHeaderComponent from './common/PopupHeaderComponent';
import PopupBodyComponent from './common/PopupBodyComponent';
import PopupBaseComponent from './common/PopupBaseComponent';
import ClosePageButtonComponent from '../commons/ClosePageButtonComponent'
import RightBodyComponent from './common/RightBodyComponent';
import LeftBodyComponent from './common/LeftBodyComponent';

import membersAdd from '../../graphics/Popups/IconMembersAdd.png'
import FooterButtonsComponent from './common/FooterButtonsComponent';
import OutsideGlowComponent from './common/OutsideGlowComponent';


const AddPartnersComponent = () => {

    const inputRefName = useRef()
    const inputRefEmail = useRef()
    const inputRefPass = useRef()
    const inputRefPassConf = useRef()
    const keyboardRef = useRef()


    const [isOnFocusName, setIsOnFocusName] = useState(false)
    const [isOnFocusEmail, setIsOnFocusEmail] = useState(false)
    const [isOnFocusPass, setIsOnFocusPass] = useState(false)
    const [isOnFocusPassConf, setIsOnFocusPassConf] = useState(false)
    const [error, setError] = useState(false)


    const { language, selectedProject, socketRef } = useContext(GlobalContext);
    const { setAddPartners } = useContext(ProjectDetailsContext);

 

    function handleEmailAdd(){
        if(inputRefPass.current.value === inputRefPassConf.current.value){
            socketRef.current.emit("CREATE_USER_AND_ADD_TO_PROJECT", { 
                projectId: selectedProject._id, 
                userData: {
                    "name": inputRefName.current.value, 
                    "email": inputRefEmail.current.value,
                    "password": inputRefPass.current.value,
                    "role": "PARTNER",
                } 
            }, (callback) => {
                console.log("PARTNER", callback)
                setAddPartners(false)
            })
            
        } else {
            setError(true)
        }
        
    }

    
    return (
        <PopupBaseComponent>
            <PopupHeaderComponent text={language.add_guests['Add Guest']}/>
            <PopupBodyComponent style={{width: 4251, height: 1633}}>
                <ClosePageButtonComponent setClose={() => setAddPartners(false)} />
                <LeftBodyComponent image={membersAdd} title="Add partner to project" description="Create and add a new partner."/>
                <RightBodyComponent>
                    <div className={styles.title}>Name</div>
                    {isOnFocusName && <OutsideGlowComponent style={{top: 232, left: 1231.5}}/>}
                    <InputTextComponent ref={inputRefName} keyboardRef={keyboardRef} value="" placeHolder="Enter name here" styleType='POPUP' width= "1560px" fontSize="40px" setIsOnFocus={setIsOnFocusName}/>
                    {!isOnFocusName && <FontAwesomeIcon className={styles.iconEdit} icon={byPrefixAndName.fal['pen']} />}
                    <div className={styles.title2}>Email</div>
                    {isOnFocusEmail && <OutsideGlowComponent style={{top: 458.5, left: 1231.5}}/>}
                    <InputTextComponent ref={inputRefEmail} keyboardRef={keyboardRef} value="" placeHolder="Enter email here" styleType='POPUP' width= "1560px" fontSize="40px" setIsOnFocus={setIsOnFocusEmail}/>
                    {!isOnFocusEmail && <FontAwesomeIcon className={styles.iconEdit} style={{top: 310}} icon={byPrefixAndName.fal['pen']} />}
                    <div className={styles.title2}>Password</div>
                    {isOnFocusPass && <OutsideGlowComponent style={{top: 685, left: 1231.5}}/>}
                    <InputTextComponent type="password" ref={inputRefPass} keyboardRef={keyboardRef} value="" placeHolder="Enter password here" styleType='POPUP' width= "1560px" fontSize="40px" setIsOnFocus={setIsOnFocusPass}/>
                    {!isOnFocusPass && <FontAwesomeIcon className={styles.iconEdit} style={{top: 535}} icon={byPrefixAndName.fal['pen']} />}
                    <div className={styles.title2}>Confirm Password</div>
                    {isOnFocusPassConf && <OutsideGlowComponent style={{top: 912, left: 1231.5}}/>}
                    <InputTextComponent type="password" ref={inputRefPassConf} keyboardRef={keyboardRef} value="" placeHolder="Renter password here" styleType='POPUP' width= "1560px" fontSize="40px" setIsOnFocus={setIsOnFocusPassConf}/>
                    {!isOnFocusPassConf && <FontAwesomeIcon className={styles.iconEdit} style={{top: 760}} icon={byPrefixAndName.fal['pen']} />}
                    {error && <div className={styles.passwordMistake}>Passwords do not match. Please ensure password and Confirm Password fields are identical.</div>}
                    <FooterButtonsComponent lightText={"Add"} lightClick={handleEmailAdd} darkClick={setAddPartners}/>
                {/* <KeyboardComponent visible ref={keyboardRef} x="330" y="250" width="750" height="450" s="1.8"/> */}
                </RightBodyComponent>
            </PopupBodyComponent>
            
        </PopupBaseComponent>
    );

}

export default AddPartnersComponent;