import { useContext, useState, useRef, useEffect } from 'react';
import { GlobalConfigContext } from "../../contexts/globalConfigContext";

import "./pages.css";

const CFG_FunctionsPage = () => {
    const { configSocketRef, setCurrentError } = useContext(GlobalConfigContext);
    const [entities, setEntities] = useState([])
    const [selectedEntity, setSelectedEntity] = useState(null);
    const entityName = "Function";

    const inputNameRef = useRef();
    const inputCodeRef = useRef();    

    useEffect(() => {
        if (selectedEntity) {
            inputNameRef.current.value = selectedEntity.name;
            inputCodeRef.current.value = selectedEntity.code;
        } else {
            inputNameRef.current.value = null;
            inputCodeRef.current.value = null;
        }
    }, [selectedEntity]);

    useEffect(() => {
        configSocketRef.current.emit("CONFIG_ALL_FUNCTIONS", (functions) => {
            setEntities(functions)
        });
    }, []);

    const addEntity = () => {
        if (inputNameRef.current.value && inputCodeRef.current.value) {
            configSocketRef.current.emit("CONFIG_ADD_FUNCTION", { id: selectedEntity?._id, name: inputNameRef.current.value, code: inputCodeRef.current.value }, (functions) => {
                setEntities(functions)
            });
        }
        setSelectedEntity(null);
    }   

    const deleteEntity = () => {
        configSocketRef.current.emit("CONFIG_DELETE_FUNCTION", selectedEntity._id, (functions) => {
            if (typeof functions === 'string') {
                setCurrentError(functions);
            } else {
                setEntities(functions);
            }
        });
        setSelectedEntity(null);
    }

    return (
        <>
            <div className="pagetitle">
                <h1>{entityName}s</h1>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-lg-12">

                        <div className="card">
                            <div className="card-body">

                                <table className="table datatable" id="MyTable">
                                    <thead>
                                        <tr>
                                            <th>
                                                {entityName} Name
                                            </th>
                                            <th>
                                                Code
                                            </th>
                                            <th>

                                            </th>
                                            <th>

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {entities.map((entity) => (
                                            <tr key={entity._id} className='trx'>
                                                <td>{entity.name}</td>
                                                <td>{entity.code}</td>
                                                <td style={{ width: "40px" }}>
                                                    <a id="editButton" className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#addEntityModal" onClick={() => setSelectedEntity(entity)}><i className="bi bi-vector-pen"></i></a>
                                                </td>
                                                <td style={{ width: "40px" }}>
                                                    <a id="deleteButton" className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#deleteEntityModal" onClick={() => setSelectedEntity(entity)} ><i className="bi bi-trash"></i></a>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <div style={{ position: 'fixed', bottom: "20px", right: "20px", width: "40px", height: "40px", fontSize: "30px", borderRadius: "40px", boxShadow: "0px 2px 10px #00000080" }} >
                <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#addEntityModal">
                    <i className="bi bi-plus" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}></i>
                </a>
            </div>

            <div className="modal fade" id="addEntityModal" tabIndex="-2">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{selectedEntity?"Edit":"Add"} {entityName}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setSelectedEntity(null)}></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-floating mb-3">
                                <input ref={inputNameRef} type="text" className="form-control" placeholder="Name" />
                                <label>Name</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input ref={inputCodeRef} type="text" className="form-control" placeholder="Code" />
                                <label>Code</label>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setSelectedEntity(null)}>Close</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => addEntity()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="deleteEntityModal" tabIndex="-2">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete {entityName}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setSelectedEntity(null)}></button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to delete <b>{selectedEntity?.name}</b> ?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setSelectedEntity(null)}>Close</button>
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => deleteEntity()}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default CFG_FunctionsPage;
