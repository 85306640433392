
import { useContext, useEffect, useRef } from 'react';
import styles from './PD_FileComponent.module.css'
import { ProjectDetailsContext } from "../../../contexts/projectDetailsContext";
import { GlobalContext } from "../../../contexts/globalContext";

import MoreButtonComponent from '../../commons/MoreButtonComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';


const PD_FileComponent = ({ file }) => {
    const moreButtonRef = useRef()

    const { selectedRefTypeForFiles, selectedTopicIdRef, socketRef, selectedProject } = useContext(GlobalContext);

    const { moreClicked, setMoreClicked } = useContext(ProjectDetailsContext);

    function handleDeleteFile() {
        socketRef.current.emit("PROJECT_DELETE_DOCUMENT", { parentId: selectedTopicIdRef.current, refType:selectedRefTypeForFiles, projectFileId: file._id, projectId: selectedProject._id }, () => {
            setMoreClicked({})
        })
    }

    const icon = (() => {
        if (file.docsFile.type.includes("image")) {
            return "file-image"
        } else if (file.docsFile.type.includes("video")) {
            return "file-video"
        } else if (file.docsFile.type.includes("pdf")) {
            return "file-pdf"
        } else if (file.docsFile.type.includes("word")) {
            return "file-word"
        } else if (file.docsFile.type.includes("excel")) {
            return "file-excel"
        } else {
            return "file-lines"
        }
    })();

    function handleClick() {
        if (moreClicked.shown && file._id === moreClicked.id) {
            setMoreClicked({})
        } else {
            setMoreClicked({
                ...moreClicked,
                shown: true,
                id: file._id,
                clickRemove: () => handleDeleteFile(),
                x: moreButtonRef.current.getBoundingClientRect().x,
                y: moreButtonRef.current.getBoundingClientRect().y
            })
        }
    }

    return (
        <div key={file._id} className={styles.element} >
            <div className={styles.info}>
                <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas[icon]} />

                <div className={styles.participantDetails}>
                    {file.docsFile.name}
                    <div className={styles.participantDescription}>
                        Added by:
                        <div className={styles.participantRole}>{file.addedBy?.name}</div>
                    </div>
                    <div className={styles.participantDescription}>
                        Owner:
                        <div className={styles.participantRole}>{file.docsFile.users[0]}</div>
                    </div>
                </div>
            </div>
            <MoreButtonComponent ref={moreButtonRef} clicked={moreClicked} setClicked={handleClick} id={file._id} />
        </div>
    );
}

export default PD_FileComponent;