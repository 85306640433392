import { useEffect, useRef, useContext } from 'react';
import { GlobalContext } from "../../contexts/globalContext";

const CurrentParticipantVideoComponent = () => {

    const videoRef = useRef(null);

    const { currentParticipantStream } = useContext(GlobalContext)

    useEffect(() => {
        //console.log("creare video", props.videoStream.producerId);
        videoRef.current.srcObject = currentParticipantStream;
    }, [currentParticipantStream])

    

    return (
        <div style={{ borderRadius:"50%", width:"170px", height:"170px", display:"flex", justifyContent:"center", overflow:"hidden", marginLeft:"10px", marginTop:"10px"}}>
            <video ref={videoRef} style={{}} width="auto" height="180px" autoPlay muted></video>
        </div>
    );
}

export default CurrentParticipantVideoComponent;