import { useEffect, useContext, useState } from 'react';

import { GlobalContext } from "../../contexts/globalContext";

const TopicComponent = ({ topic, index }) => {

    const { currentTopic, currentParticipant } = useContext(GlobalContext);
    const [topicNumber, setTopicNumber] = useState("01");
    
    useEffect(() => {
        if (index < 10) {
            setTopicNumber("0"+index);
        } else {
            setTopicNumber(""+index);
        }
        
    }, [])

    const GetIcon = (type) => {
        switch (type) {
            case "doc":
                return <i className="bi bi-file-earmark-text" style={{ fontSize: "70px" }}></i>;
            case "sheet":
                return <i className="bi bi-file-earmark-spreadsheet" style={{ fontSize: "70px" }}></i>;
            case "image":
                return <i className="bi bi-file-easel" style={{ fontSize: "70px" }}></i>;
            case "video":
                return <i className="bi bi-file-slides" style={{ fontSize: "70px" }}></i>;
            case "pdf":
                return <i className="bi bi-file-earmark-pdf" style={{ fontSize: "70px" }}></i>;
            default:
                return <i className="bi bi-file-earmark-text" style={{ fontSize: "70px" }}></i>;
        }
    }

    return (

        <div style={{ display: "flex", flexDirection: "column", width: "auto", height: "auto" }}>
            <div style={{ borderRadius: "10px", display: "flex", alignItems: "center", padding: "0px", margin: "2px 0px", color: "white", width: "900px", height: "140px", backgroundColor: (topic._id == currentTopic?._id ? "#292148" : "#161032"), fontSize: "30px", overflow:"hidden" }}>
                {currentTopic?._id == topic._id &&
                    <>
                        <div style={{ height:"100%", fontSize: "74px", fontWeight: "bold", padding: "0px 10px", color: "#33FFC8", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor:"#423467" }}>{topicNumber}</div>
                        <div style={{ width: "100%", marginLeft:"20px" }}>
                            <div style={{ fontSize: "40px", color: "#33FFC8" }}>{topic.name}</div>
                            <div style={{ fontSize: "32px", color: "#EEEEEE" }}><i className="bi bi-mic" style={{ fontSize: "32px" }}> </i>{topic?.moderator.name}</div>
                        </div>
                    </>
                }
                {currentTopic?._id != topic._id &&
                    <>
                        <div style={{ fontSize: "74px", fontWeight: "bold", margin: "0px 20px", color: "#393357", display: "flex", justifyContent: "center", alignItems: "center" }}>{topicNumber}</div>
                        <div style={{ width: "100%" }}>
                            <div style={{ fontSize: "40px", color: "#393357" }}>{topic.name}</div>
                            <div style={{ fontSize: "32px", color: "#393357" }}><i className="bi bi-mic" style={{ fontSize: "32px" }}> </i>{topic?.moderator.name}</div>
                        </div>
                    </>
                }


            </div>

            {topic.moderator._id == currentParticipant._id &&


                topic.files.map((file) => (
                    <div key={file._id} style={{ color: "white", fontSize: "40px", marginLeft: "40px", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {GetIcon(file.docsFile.type)}{file.docsFile.name}1
                    </div>
                ))



            }
        </div>
    );
}

export default TopicComponent;