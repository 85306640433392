import { useRef, useContext, useEffect } from 'react';
import { GlobalConfigContext } from "../contexts/globalConfigContext";

import { ApiLoginConfigUser } from '../utils/RestServices';

const CFG_LoginComponent = () => {

    const inputUserNameRef = useRef()
    const inputPasswordRef = useRef()

    const { setCurrentUser } = useContext(GlobalConfigContext);

    useEffect(() => {
        inputUserNameRef.current.value = "andrei.lucaci@superadmin.ro";
        inputPasswordRef.current.value = "andrei123";
    }, [])

    function login() {
        var body = JSON.stringify({
            email: inputUserNameRef.current.value,
            password: inputPasswordRef.current.value
        },);

        ApiLoginConfigUser(body, data => {
            setCurrentUser(data);
        });
    }

    return (
        <main>
            <div className="container">
                <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                                <div className="d-flex justify-content-center py-4">
                                    <a href="index.html" className="logo d-flex align-items-center w-auto">
                                        <img src="img/logo-tek-black.svg" alt=""/>                                            
                                    </a>
                                </div>

                                <div className="card mb-3">

                                    <div className="card-body">

                                        <div className="pt-4 pb-2">
                                            <h5 className="card-title text-center pb-0 fs-4">Login to Configuration Account</h5>
                                            <p className="text-center small">Enter your username & password to login</p>
                                        </div>

                                        <div className="row g-3 needs-validation">

                                            <div className="col-12">
                                                <label className="form-label">Username</label>
                                                <div className="input-group has-validation">
                                                    <span className="input-group-text" id="inputGroupPrepend">@</span>
                                                    <input ref={inputUserNameRef} type="text" name="username" className="form-control" id="yourUsername" required />
                                                        <div className="invalid-feedback">Please enter your username.</div>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <label className="form-label">Password</label>
                                                <input ref={inputPasswordRef} type="password" name="password" className="form-control" id="yourPassword" required />
                                                    <div className="invalid-feedback">Please enter your password!</div>
                                            </div>

                                            
                                            <div className="col-12">
                                                <button className="btn btn-primary w-100" onClick={()=> login()}>Login</button>
                                            </div>
                                            
                                        </div>

                                    </div>
                                </div>                               
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    );
}

export default CFG_LoginComponent;