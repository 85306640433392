import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import CFG_App from './configComponents/CFG_App';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GlobalProvider } from "./contexts/globalContext"
import { GlobalConfigProvider } from "./contexts/globalConfigContext"

import { BrowserRouter, Route, Routes } from "react-router-dom"


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <BrowserRouter>
        <Routes>
            <Route path="/" element={<GlobalProvider>
                <App />
            </GlobalProvider>} />
            <Route path="/config" element={<GlobalConfigProvider>
                <CFG_App />
            </GlobalConfigProvider>} />
        </Routes>
    </BrowserRouter>
);


reportWebVitals();
