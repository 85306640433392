import { useEffect, useRef, useState, useContext } from 'react';

import { GlobalContext } from "../../contexts/globalContext";

const SideMenuComponent = ({ SetWaitingForJoining }) => {

    const { currentConference, conferenceSocketRef, currentParticipant } = useContext(GlobalContext)

    const [hour, setHour] = useState("00");
    const [minutes, setMinutes] = useState("00");
    const [dateString, setDateString] = useState("00");

    const intervalRef = useRef();

    useEffect(() => {
        const date = new Date();
        setHour(date.getHours());
        setMinutes(date.getMinutes());
        setDateString(date.toDateString());


        intervalRef.current = setInterval(() => {
            const date = new Date();
            setHour(date.getHours());
            setMinutes(date.getMinutes());
            setDateString(date.toDateString());
        }, 1000);

        return () => {
            clearInterval(intervalRef.current);
        }

    }, []);

    const StopConference = () => {
        conferenceSocketRef.current.emit("STOP_CONFERENCE", currentConference._id);
    }

    return (

        <div style={{ position: "absolute", display: "flex", flexDirection: "column", width: "125px", height: "auto", bottom: "20px", left: "20px", top: "20px", backgroundColor: "#292148", borderRadius: "10px", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <i className="bi bi-clock" style={{ fontSize: "70px", color: "#726FA8" }}> </i>
                <div style={{ fontSize: "71px", color: "white", fontWeight: "bold", marginTop: "-20px" }}> {hour}</div>
                <div style={{ fontSize: "70px", color: "white", marginTop: "-30px" }}> {minutes}</div>
                <div style={{ fontSize: "28px", color: "#726FA8", marginTop: "-30px", margin: "0px 10px" }}> {dateString}</div>


            </div>

            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>

                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px" }}>
                    <i className="bi bi-chat" style={{ fontSize: "70px", color: "#726FA8" }}> </i>
                    <div style={{ fontSize: "20px", color: "#726FA8", marginTop: "-20px", margin: "0px 10px" }}>CHAT</div>
                </div>

                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px" }}>
                    <i className="bi bi-calculator" style={{ fontSize: "70px", color: "#726FA8" }}> </i>
                    <div style={{ fontSize: "20px", color: "#726FA8", marginTop: "-20px", margin: "0px 10px" }}>CALCULATOR</div>
                </div>

                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px" }}>
                    <i className="bi bi-journals" style={{ fontSize: "70px", color: "#726FA8" }}> </i>
                    <div style={{ fontSize: "20px", color: "#726FA8", marginTop: "-20px", margin: "0px 10px" }}>NOTES</div>
                </div>

                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px" }}>
                    <i className="bi bi-browser-chrome" style={{ fontSize: "70px", color: "#726FA8" }}> </i>
                    <div style={{ fontSize: "20px", color: "#726FA8", marginTop: "-20px", margin: "0px 10px" }}>BROWSER</div>
                </div>

                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px" }}>
                    <i className="bi bi-currency-exchange" style={{ fontSize: "70px", color: "#726FA8" }}> </i>
                    <div style={{ fontSize: "20px", color: "#726FA8", marginTop: "-20px", margin: "0px 10px" }}>CURRENCY</div>
                </div>

                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <i className="bi bi-calendar" style={{ fontSize: "70px", color: "#726FA8" }}> </i>
                    <div style={{ fontSize: "20px", color: "#726FA8", marginTop: "-20px", margin: "0px 10px" }}>CALENDAR</div>
                </div>

            </div>

            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }} onClick={() => SetWaitingForJoining(true)}>
                    <i className="bi bi-pause" style={{ fontSize: "70px", color: "#726FA8" }}> </i>
                    <div style={{ fontSize: "28px", color: "#726FA8", marginTop: "-20px", margin: "0px 10px" }}>PAUSE</div>
                </div>
                {currentConference?.topics[0].moderator._id == currentParticipant._id &&
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }} onClick={() => StopConference()}>
                        <i className="bi bi-power" style={{ fontSize: "70px", color: "#726FA8" }}> </i>
                        <div style={{ fontSize: "28px", color: "#726FA8", marginTop: "-20px", margin: "0px 10px" }}>CLOSE</div>
                    </div>
                }

            </div>

        </div>
    );
}

export default SideMenuComponent;