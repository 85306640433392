import { useRef, useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../../contexts/globalContext";
import { ProjectDetailsContext } from '../../contexts/projectDetailsContext';
import { AddConferenceContext } from '../../contexts/addConferenceContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import styles from './AddConferenceComponentNew.module.css'

import DarkButtonComponent from '../commons/DarkButtonComponent';
import LightButtonComponent from '../commons/LightButtonComponent';

import video from '../../graphics/big wave fundal loop_6.mp4'

import PopupBaseComponent from './common/PopupBaseComponent';

import InputTextComponent from '../keyboard/InputTextComponent';

import DatePickerComponent from './conferenceAddComponents/DatePickerComponent';
import TimePickerComponent from './conferenceAddComponents/TimePickerComponent';

import { translate, compose, toString, identity, fromString } from 'transformation-matrix';
import ConferenceSendMessagesComponent from './conferenceAddComponents/ConferenceSendMessagesComponent';
import MultipleRoomsHourPickerComponent from './conferenceAddComponents/MultipleRoomsHourPickerComponent';
import ManageParticipantsComponent from './conferenceManageParticipants/ManageParticipantsComponent';




const AddConferenceComponentNew = () => {

    const { setCreateConference, editConference, setEditConference } = useContext(ProjectDetailsContext);
    const { language, selectedProject, socketRef, appHeight, appWidth } = useContext(GlobalContext);
    const {currentDate, calendarRef, initialHourEndRef, initialHourStartRef, selectedHourRef, titleRef, keyboardRef, dataChanged, hasIntersection,
        isConfNameOnFocus, setIsConfNameOnFocus,
        setDayConferences,
        unsaved, setUnsaved,
        save, setSave,
        dataChangedLocal,
        selectedRooms, conferenceDetailsForParticipants, setConferenceDetailsForParticipants
    } = useContext(AddConferenceContext)

    const [selectedDate, setSelectedDate] = useState(editConference ? new Date(editConference.scheduleDate) : currentDate)

    const [selectedHourStart, setSelectedHourStart] = useState(
        editConference ? new Date(editConference.scheduleDate).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }) : "12:00"
    )
    const [selectedHourEnd, setSelectedHourEnd] = useState(() => {
        if (editConference) {
            const hourStart = new Date(editConference.scheduleDate)
            const hourEnd = new Date(hourStart.getTime() + editConference.duration * 60000);

            return hourEnd.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            })
        }
        return '13:00'
    })
    const [duration, setDuration] = useState(() => {
        if (editConference) {
            const hours = Math.floor(editConference.duration / 60);
            const mins = editConference.duration % 60;

            return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
        }
        return '01:00'
    })

    const buttonStyling = { width: 358, height: 82, borderRadius: 16, font: 'normal normal 600 40px/33px bio-sans', margin: 22 }

    const hourWidth = 181.7

    useEffect(() => {

        initialHourStartRef.current = selectedHourStart.split(':')[0]
        initialHourEndRef.current = selectedHourEnd.split(':')[0]

        const selectedDateStartOfDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
        const currentDateStartOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())

        const diffInMilliseconds = Math.abs(selectedDateStartOfDay.getTime() - currentDateStartOfDay.getTime())

        calendarRef.current.scrollLeft = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24)) * 156

        dataChanged.current = false

        moveRectangle()
    }, [])

    useEffect(() => {
        socketRef.current.emit("GET_CONFERENCES_FOR_ONE_DAY_FOR_ALL_ROOMS", selectedDate.toUTCString(), callback => {
            setDayConferences(callback)

            let [startHours, startMinutes] = selectedHourStart.split(':');
            let [endHours, endMinutes] = selectedHourEnd.split(':');

            let startDate = new Date();
            startDate.setHours(startHours, startMinutes, 0);

            let endDate = new Date();
            endDate.setHours(endHours, endMinutes, 0);

            hasIntersection.current = callback.some(conference => {
                if (editConference) {
                    if (editConference._id === conference._id) return false
                }

                const conferenceStart = new Date(conference.scheduleDate);

                const conferenceStartToday = new Date();
                conferenceStartToday.setHours(conferenceStart.getHours(), conferenceStart.getMinutes(), 0, 0);

                const conferenceEnd = new Date(conferenceStartToday.getTime() + conference.duration * 60000);

                return (
                    (startDate <= conferenceEnd && endDate > conferenceStartToday)
                );
            });

        })



    }, [selectedDate])

    useEffect(() => {

        let [startHours, startMinutes] = selectedHourStart.split(':');
        let [endHours, endMinutes] = selectedHourEnd.split(':');

        let startDate = new Date();
        startDate.setHours(startHours, startMinutes, 0);

        let endDate = new Date();
        endDate.setHours(endHours, endMinutes, 0);

        let diffMs = endDate - startDate;

        let diffMinutes = Math.floor(diffMs / 1000 / 60);
        let hours = Math.floor(diffMinutes / 60);
        let minutes = diffMinutes % 60;

        if (minutes < 10) {
            minutes = `0${minutes}`
        } else {
            minutes = `${minutes}`
        }

        if (hours < 10) {
            hours = `0${hours}`
        } else {
            hours = `${hours}`
        }

        let currentDuration = hours + ':' + minutes

        setDuration(currentDuration)


    }, [selectedHourStart, selectedHourEnd])

    useEffect(() => {
        if (dataChangedLocal) dataChanged.current = true
    }, [dataChangedLocal])

    const moveRectangle = () => {
        var matRectangle = identity();

        if(selectedHourRef.current){
            if (selectedHourRef.current?.style.transform !== '') {
                matRectangle = fromString(selectedHourRef.current.style.transform);
            }
    
            const translationDiff = parseInt(selectedHourStart.split(':')[1])
            var transRectangle = translate(translationDiff / 60 * hourWidth, 0);
    
            if (editConference) {
                const scheduleDate = new Date(editConference.scheduleDate);
                const duration = editConference.duration;
    
                const endTime = new Date(scheduleDate.getTime() + duration * 60000);
    
                const twoHourMark = new Date(scheduleDate.getTime());
                twoHourMark.setHours(scheduleDate.getHours() + 4, 0, 0, 0);
                const threeHourMark = new Date(scheduleDate.getTime());
                threeHourMark.setHours(scheduleDate.getHours() + 5, 0, 0, 0);
                const forHourMark = new Date(scheduleDate.getTime());
                forHourMark.setHours(scheduleDate.getHours() + 6, 0, 0, 0);
                const fiveHourMark = new Date(scheduleDate.getTime());
                fiveHourMark.setHours(scheduleDate.getHours() + 7, 0, 0, 0);
    
                if (endTime > fiveHourMark) {
                    transRectangle = translate(translationDiff / 60 * hourWidth - hourWidth * 4, 0);
                } else if (endTime > forHourMark) {
                    transRectangle = translate(translationDiff / 60 * hourWidth - hourWidth * 3, 0);
                } else if (endTime > threeHourMark) {
                    transRectangle = translate(translationDiff / 60 * hourWidth - hourWidth * 2, 0);
                } else if (endTime > twoHourMark) {
                    transRectangle = translate(translationDiff / 60 * hourWidth - hourWidth, 0);
                }
            }
    
    
            var finalRectangle = compose(transRectangle, matRectangle);
            selectedHourRef.current.style.transform = toString(finalRectangle);
        }

        
    }

    const addConference = () => {

        let formattedDate = new Date(selectedDate)
        formattedDate.setHours(selectedHourStart.split(":")[0])
        formattedDate.setMinutes(selectedHourStart.split(":")[1])

        const [hours, minutes] = duration.split(":");

        socketRef.current.emit("UPDATE_CONFERENCE", {
            projectId: selectedProject._id,
            conferenceId: editConference ? editConference._id : null,
            newData: {
                name: titleRef.current?.value ? titleRef.current.value : "Default Title",
                scheduleDate: formattedDate.toUTCString(),
                duration: parseInt(hours) * 60 + parseInt(minutes),
                attachedParticipants: [],
                type: 'Conference',
                rooms: selectedRooms
            }
        }, (data) => {
            console.log(data.response)
            if(!conferenceDetailsForParticipants)
                setConferenceDetailsForParticipants(data.response)
            else
                if (editConference)
                    setEditConference()
                else
                    setCreateConference()

        });
    };

    const closePage = () => {
        if (!dataChanged.current) {
            setConferenceDetailsForParticipants()
            if (editConference)
                setEditConference()
            else
                setCreateConference()
        } else {
            setUnsaved(true)
        }

    }


    function onFocusInput() {
        dataChanged.current = true;
        setIsConfNameOnFocus(true)
    }

    function closeWithoutSave() {
        setEditConference()
        setCreateConference()
        // setConferenceDetailsForParticipants()
        // setUnsaved(false)
    }

    return (
        
        <PopupBaseComponent flexDirection='column'>
            {!conferenceDetailsForParticipants && <>
            <video id="background-video" className={styles.background} autoPlay loop playsInline muted style={{ height: appHeight, width: appWidth }}>
                <source src={video} type="video/mp4" />
            </video>

            <div className={styles.title}>
                Conference title
                <InputTextComponent ref={titleRef} onFocus={onFocusInput} keyboardRef={keyboardRef} value={editConference ? editConference.name : "New Conference"} fontSize="160px" placeHolder="New Conference" styleType='CONFERENCE' />
            </div>

            <DatePickerComponent selectedDate={selectedDate} setSelectedDate={setSelectedDate} />

            <MultipleRoomsHourPickerComponent selectedDate={selectedDate} selectedHourStart={selectedHourStart} setSelectedHourStart={setSelectedHourStart}
                setSelectedHourEnd={setSelectedHourEnd} duration={duration}  selectedHourEnd={selectedHourEnd} />


            <TimePickerComponent selectedHourRef={selectedHourRef} selectedHourStart={selectedHourStart} setSelectedHourStart={setSelectedHourStart} 
                selectedHourEnd={selectedHourEnd} setSelectedHourEnd={setSelectedHourEnd}/>


            <div className={styles.duration}>
                Duration
                <div className={styles.durationInput}>
                    <span style={{ fontWeight: 'bold' }}>{duration.split(':')[0]}</span>:{duration.split(':')[1]}
                </div>
                <div className={styles.hoursMin}>
                    <div className={styles.hoursMinLeft}>hours</div>
                    min
                </div>
            </div>
            
            <div className={styles.leftShadow}></div>
            <div className={styles.rightShadow}></div>
            
            </>}
            {conferenceDetailsForParticipants && <ManageParticipantsComponent/>}

            <div className={styles.buttonDiv}>
                {dataChanged?.current && 
                    <LightButtonComponent
                        style={{
                            width: 432,
                            height: 82,
                            borderRadius: 8,
                            font: 'normal normal 600 40px/33px bio-sans',
                            marginLeft: 30,
                            background: "transparent linear-gradient(90deg, #77EDDC 0%, #DBFD95 97%, #DEFE93 100%) 0% 0% no-repeat padding-box"
                        }} onClick={addConference}>
                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['arrow-right']} />Next step
                    </LightButtonComponent>

                } 

                <DarkButtonComponent
                    style={{
                        width: 353,
                        height: 80,
                        borderRadius: 8,
                        font: 'normal normal 600 40px/33px bio-sans',
                        marginLeft: 30
                    }}
                    onClick={closePage}
                >
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['xmark']} />Close
                </DarkButtonComponent>
            </div>

            {unsaved && <ConferenceSendMessagesComponent type="unsaved">
                <LightButtonComponent style={buttonStyling} onClick={() => setSave(true)}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['save']} /> Save
                </LightButtonComponent>
                <DarkButtonComponent style={buttonStyling} onClick={closeWithoutSave}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['close']} /> Don't save
                </DarkButtonComponent>
                <DarkButtonComponent style={buttonStyling} onClick={() => setUnsaved(false)}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['close']} /> Cancel
                </DarkButtonComponent>
                
            </ConferenceSendMessagesComponent>}
            {save && <ConferenceSendMessagesComponent type="saved">
                <DarkButtonComponent style={buttonStyling} onClick={addConference}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['close']} /> Close
                </DarkButtonComponent>
            </ConferenceSendMessagesComponent>}
        </PopupBaseComponent>
    );

}

export default AddConferenceComponentNew;