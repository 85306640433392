
import { useContext, useEffect, useRef, useState } from 'react';
import styles from './PD_TopicComponent.module.css'
import { ProjectDetailsContext } from "../../../contexts/projectDetailsContext";
import { GlobalContext } from "../../../contexts/globalContext";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'

import MoreButtonComponent from '../../commons/MoreButtonComponent';


const PD_TopicComponent = ({ topic }) => {
    const moreButtonRef = useRef()
    

    const { selectedTopic, setSelectedTopic, selectedTopicIdRef, language, socketRef, selectedProject, setTopicFiles, selectedParentForFiles, setSelectedParentForFiles, setSelectedRefTypeForFiles } = useContext(GlobalContext);

    const { setEditTopic, moreClicked, setMoreClicked } = useContext(ProjectDetailsContext);

    

    function deleteTopic() {
        socketRef.current.emit("PROJECT_DELETE_FOLDER", topic._id, selectedProject._id, () => setMoreClicked({}))

    }

    function handleClick() {
        if (moreClicked.shown && topic._id === moreClicked.id) {
            setMoreClicked({})
        } else {
            setMoreClicked({
                ...moreClicked,
                shown: true,
                id: topic._id,
                clickRemove: () => deleteTopic(),
                clickEdit: () => setEditTopic(topic),
                x: moreButtonRef.current.getBoundingClientRect().x,
                y: moreButtonRef.current.getBoundingClientRect().y
            })
        }
    }

    const selectTopicForViewFiles = (e, parent, refType) => {
        e.stopPropagation();

        selectedTopicIdRef.current = parent._id;
        setSelectedParentForFiles(parent);
        setSelectedRefTypeForFiles(refType);

        setSelectedTopic(topic._id)

        socketRef.current.emit("PROJECT_GET_FOLDER_ATTACHED_DOCUMENTS", { parentId: parent._id }, (docs) => {

            setTopicFiles(docs);
        });
    };

    return (
        <div key={topic._id} className={styles.element} onClick={(e) => selectTopicForViewFiles(e, topic, 'Topic')} style={{ background: selectedTopic === topic._id || selectedParentForFiles?._id === topic._id ? "#231A3E 0% 0% no-repeat padding-box" : "" }}>
            <div className={styles.headerTopic}>
                {topic.name}
                <MoreButtonComponent ref={moreButtonRef} clicked={moreClicked} setClicked={handleClick} id={topic._id} />
            </div>
            <div className={styles.subtopicsTitle}>
                <FontAwesomeIcon className={styles.bookmark} icon={byPrefixAndName.fal['bookmark']} />
                Subfolders
            </div>
            {topic.subtopics?.map((subtopic) => (
                <div key={subtopic._id} className={styles.subtopic} onClick={(e) => selectTopicForViewFiles(e, subtopic, 'Subtopic')} style={{ background: selectedParentForFiles?._id === subtopic._id ? "#3F3765 0% 0% no-repeat padding-box" : "" }}>
                    {subtopic.name}
                </div>
            ))}

        </div>
    );
}

export default PD_TopicComponent;