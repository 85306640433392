import { useEffect, useContext } from 'react';

import { GlobalContext } from "../../contexts/globalContext";
import CurrentParticipantVideoComponent from './CurrentParticipantVideoComponent';

const ConfHeaderComponent = (props) => {

    const { currentConference, currentTopic, currentParticipant, conferenceSocketRef, currentParticipantStream } = useContext(GlobalContext)

    const GoToNextTopic = () => {
        conferenceSocketRef.current.emit("GO_TO_NEXT_TOPIC", currentConference._id);
    }

    const StopConference = () => {
        conferenceSocketRef.current.emit("STOP_CONFERENCE", currentConference._id);
    }

    return (
        <div style={{ display:"flex", flexDirection:"row", position: "relative", width: "900px", height: "190px", background: "#EEEEEE", borderRadius: "10px", marginBottom: "10px" }}>
            {currentParticipantStream && <CurrentParticipantVideoComponent/>}
            <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between", padding:"20px 30px"}}>
                <div style={{fontSize: "28px", color:"#050020"}}>Welcome</div>
                <div style={{fontSize: "50px", color:"#050020"}}>{currentParticipant.name}</div>
                <div style={{fontSize: "30px", color:"#726FA8"}}>Brand ambassador</div>
            </div>
            { currentParticipant._id == currentTopic?.moderator._id && <div style={{position:"absolute", right:"10px", top:"10px", backgroundColor:"#FF3B00", color:"white", fontSize:"30px", padding:"5px 20px", display:"flex", flexDirection:"row", alignItems:"center", borderRadius:"10px"}}><i style={{fontSize:"20px", marginRight:"10px"}} className="bi bi-circle-fill"></i> YOU ARE LIVE</div>}
            { currentTopic?.moderator._id == currentParticipant._id && <button style={{ position:"absolute", right:"10px", bottom:"10px", width:"250px", border:"1px solid gray", backgroundColor:"white", height: "40px", padding: "10px 0px", fontSize: "30px" }} onClick={() => GoToNextTopic()}>NEXT TOPIC</button>}
            {/* { currentConference?.topics[0].moderator._id == currentParticipant._id && <button style={{position:"absolute", width:"100%", border:"1px solid gray", height: "60px", backgroundColor:"white", padding: "10px 0px", fontSize: "40px" }} onClick={() => StopConference()}>Stop Conference</button>} */}
            {/* <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center", fontSize: "50px", color: "black", marginBottom:"20px" }}> {currentConference.name}</div>

            <div style={{bottom:"0px", display:"flex", justifyContent:"space-around", width:"100%"}}>
                {currentTopic?.moderator == currentParticipant._id && <button style={{ width:"100%", border:"1px solid gray", backgroundColor:"white", height: "60px", padding: "10px 0px", fontSize: "40px" }} onClick={() => GoToNextTopic()}>Go to next topic</button>}


                
            </div> */}

        </div>
    );
}

export default ConfHeaderComponent;