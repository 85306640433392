import { useEffect, useState } from 'react';
import styles from './ConferenceScreenComponent.module.css'
import SocketManagerConference from '../../utils/SocketManagerConference';
import WaitingForJoiningScreen from './WaitingForJoiningScreen';
import MediasoupConferenceComponent from './MediasoupConferenceComponent';
import TopicsComponent from './TopicsComponent';
import SideMenuComponent from './SideMenuComponent';



const ConferenceScreenComponent = () => {
    const [waitingForJoining, setWaitingForJoining] = useState(true); 

    return (
        <div className={styles.component}>
            <SocketManagerConference></SocketManagerConference>

            {waitingForJoining && <WaitingForJoiningScreen SetWaitingForJoining={setWaitingForJoining}></WaitingForJoiningScreen>}
            {!waitingForJoining && <MediasoupConferenceComponent SetWaitingForJoining={setWaitingForJoining} />}

            
            {!waitingForJoining && <TopicsComponent SetWaitingForJoining={setWaitingForJoining}/>}
            
            {!waitingForJoining && <SideMenuComponent SetWaitingForJoining={setWaitingForJoining}/>}
            

        </div>
    );
}

export default ConferenceScreenComponent;