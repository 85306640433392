import styles from './FooterButtonsComponent.module.css'

import LightButtonComponent from '../../commons/LightButtonComponent';
import DarkButtonComponent from '../../commons/DarkButtonComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';



const FooterButtonsComponent = ({lightText, lightClick, darkClick, style}) => {
    
    return (
        <div className={styles.footerButtons} style={style}>
            <LightButtonComponent onClick={lightClick} style={{width: 377, height: 82, marginLeft: 0}}>
                <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['plus']} /> 
                {lightText}
            </LightButtonComponent>
            <DarkButtonComponent onClick={darkClick} style={{width: 377, height: 82, marginLeft: 40}}>
                <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['xmark']} /> 
                Cancel
            </DarkButtonComponent>
        </div>
    );

}

export default FooterButtonsComponent;