import styles from './ConferenceSendMessagesComponent.module.css'

import { GetMaxZIndex } from '../../../utils/UtilitaryFunctions';

import airplane from '../../../graphics/conference add/aiplane-3D.png'
import done from '../../../graphics/conference add/done-3D.png'
import exclamation from '../../../graphics/conference add/exclamation-3D.png'
import saved from '../../../graphics/conference add/saved-3D.png'

const ConferenceSendMessagesComponent = (props) => {
    
    const image = () => {
        switch (props.type) {
            case "sending":
                return airplane;
            case "sent":
                return done;
            case "unsaved":
                return exclamation;
            default:
                return saved;
        }
    }

    const title = () => {
        switch (props.type) {
            case "sending":
                return "Sending conference invitations";
            case "sent":
                return "Conference successfully set up";
            case "unsaved":
                return "Unsaved changes!";
            default:
                return "Changes saved";
        }
    }

    const message1 = () => {
        switch (props.type) {
            case "sending":
                return "Your conference invitations are currently being sent.";
            case "sent":
                return "Invitations have been sent to selected participants.";
            case "unsaved":
                return "Are you sure you want to leave? ";
            default:
                return "Your changes have been successfully saved. ";
        }
    }

    const message2 = () => {
        switch (props.type) {
            case "sending":
                return "You can undo the process within the next few moments by clicking “Undo” below.";
            case "sent":
                return "You can view invitation details in your dashboard.";
            case "unsaved":
                return "If you leave now, any progress made on this page will be lost.";
            default:
                return "You can now close this window.";
        }
    }
   

    return (
        <div className={styles.component} style={{zIndex: GetMaxZIndex() + 1}}>
            <img src={image()} className={styles.image} />
            <div className={styles.title}>{title()}</div>
            <div className={styles.message}>{message1()}</div>
            <div className={styles.message}>{message2()}</div>
            <div className={styles.buttons}>
                {props.children}
            </div>
            
        </div>
    );

}

export default ConferenceSendMessagesComponent;