import { useEffect, useRef, useContext } from 'react';
import { GlobalContext } from "../../contexts/globalContext";

const ModeratorVideoComponent = (props) => {

    const videoRef = useRef(null);
    const { currentModeratorStream } = useContext(GlobalContext)

    useEffect(() => {
        videoRef.current.srcObject = currentModeratorStream.stream;
    }, [currentModeratorStream])

    return (
        <div style={{ position: "relative" }}>
            <video ref={videoRef} style={{  }} width="100%" height="auto" autoPlay muted></video>
            <div style={{ backgroundColor:"#00000077", width:"100%", position: "absolute", left:"0px", bottom:"0px", fontSize:"40px", color:"white"}}>{currentModeratorStream.participant.name}</div>
        </div>
    );
}

export default ModeratorVideoComponent;