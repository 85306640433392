import { useEffect, useRef } from 'react';

const VideoStreamComponent = (props) => {

    const videoRef = useRef(null);

    useEffect(() => {
        //console.log("creare video", props.videoStream.producerId);
        videoRef.current.srcObject = props.videoStream.stream;
    }, [])

    return (
        <div style={{ position: "relative", marginTop:"10px" }}>
            <video ref={videoRef} style={{  }} width="auto" height={props.height} autoPlay></video>
            
            <div style={{ backgroundColor:"#00000077", width:"100%", position: "absolute", left:"0px", bottom:"0px", fontSize:"30px", color:"white"}}>{props.videoStream.participant.name}</div>
        </div>
    );
}

export default VideoStreamComponent;