

import { useContext, useEffect, useRef } from 'react';
import { GlobalContext } from "../contexts/globalContext"
import io from "socket.io-client"


const SocketManagerConference = () => {

    const { conferenceSocketRef, currentParticipant, currentConference, setCurrentConference,setCurrentParticipant } = useContext(GlobalContext);

    useEffect(() => {
        conferenceSocketRef.current = io.connect(process.env.REACT_APP_CMS_URL, {
            secure: true,
            reconnect: true,
            rejectUnauthorized: true,
            query: { token: currentParticipant.token }
        })

        conferenceSocketRef.current.on('connect', function () {
            conferenceSocketRef.current.emit("ADD_SOCKET_TO_CONFERENCE", currentConference._id);            
        });

        conferenceSocketRef.current.on('CONFERENCE_STOPED', function () {
            setCurrentConference(null);
            setCurrentParticipant(null);
        });


        return () => {
            conferenceSocketRef.current.disconnect()
            conferenceSocketRef.current = null
        }
    }, [])

    return (
        <> </>
    );
}

export default SocketManagerConference;